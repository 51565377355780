import { useState, useEffect, useContext } from "react"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { useNavigate } from "react-router-dom"
import Modal from "components/Modal/Modal"
import Payment from "views/Payment"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import { MainContainerContext } from "contexts/MainContainerProvider"

function SelectedTableHeader(props) {

    const { selectedTableUUID, orders, orderView, setOrderView } = props

    const { getT } = useMultilang()

    const { addModal } = useContext(ModalContext)
    const { setTable, addToExternalOrders, clearExternalOrders } = useContext(SelectedOrderContext)
    const { orderObjects } = useContext(MainContainerContext)

    const navigate = useNavigate()

    const [tableOrders, setTableOrders] = useState(null)
    const [discountPriceSum, setDiscountPriceSum] = useState(0)
    const [paymentsNeededSum, setPaymentsNeededSum] = useState(0)
    const [paymentsTotalSum, setPaymentsTotalSum] = useState(0)

    useEffect(() => {
        if(orders != null) {
            let obj = {}
            let discountSum = 0
            let paymentsNeedSum = 0
            let paymentsTotSum = 0
            for(let order of orders) {
                if(order.tableUUID === selectedTableUUID) {
                    obj[order.uuid] = order
                    discountSum += +order.discountPrice
                    paymentsNeedSum += +order.paymentsNeeded
                    paymentsTotSum += +order.paymentsTotal
                }
            }
            setTableOrders(obj)
            setDiscountPriceSum(discountSum)
            setPaymentsNeededSum(paymentsNeedSum)
            setPaymentsTotalSum(paymentsTotSum)
        }
        // eslint-disable-next-line
    }, [orders])

    const startNewOrderOnTable = () => {
        setTable(selectedTableUUID)
        navigate("/")
        localStorage["lastTableUUID"] = selectedTableUUID
    }

    const payTable = () => {        
        let orders = Object.values(tableOrders || {}).map(order => { return({
            uuid: order.uuid, 
            orderNumber: order.orderNumber, 
            orderIdentifier: order.orderIdentifier,
            paymentsNeeded: order.paymentsNeeded
        })})
        addToExternalOrders(orders)
        handleExternalPay()
    }

    const handleExternalPay = () => {
        addModal(
          <Modal key={uuidv4()} className="payment-modal" closeCallback={clearExternalOrders}>
            <Payment external={true} orderObjects={orderObjects} />
          </Modal>
        )
    }

    const toggleOrderView = () => {
        setOrderView(!orderView)
    }

    if(tableOrders == null) return null

    if(selectedTableUUID === "") return  <div className="order-view-table-header">{getT("orderManager.noTableSelected")}</div>

    return(
        <div className="order-view-table-header">
            <div className="table-info">
                <div className="data-block">
                    <div className="label">{getT("orderManager.tableDiscountPriceSum")}</div>
                    <div className="value">
                        <NumberFormat
                            value={+discountPriceSum}
                            decimalSeparator=","
                            suffix=" Ft"
                            thousandSeparator="."
                            displayType="text"
                        />
                    </div>
                </div>
                <div className="data-block">
                    <div className="label">{getT("orderManager.tablePaymentsNeededSum")}</div>
                    <div className="value">
                        <NumberFormat
                            value={+paymentsNeededSum}
                            decimalSeparator=","
                            suffix=" Ft"
                            thousandSeparator="."
                            displayType="text"
                        />
                    </div>
                </div>
                <div className="data-block">
                    <div className="label">{getT("orderManager.tablePaymentsTotalSum")}</div>
                    <div className="value">
                        <NumberFormat
                            value={+paymentsTotalSum}
                            decimalSeparator=","
                            suffix=" Ft"
                            thousandSeparator="."
                            displayType="text"
                        />
                    </div>
                </div>
            </div>
            <div className="actions">
                {paymentsNeededSum > 0 && <div className="btn" onClick={payTable}>{getT("orderManager.payTable")}</div>}
                <div className="btn" onClick={startNewOrderOnTable}>{getT("orderManager.startNewOrderOnTable")}</div>
                <div className={`btn ${orderView ? "btn-highlighted" : ""}`} onClick={toggleOrderView}>{getT("orderManager.orderView")}</div>
            </div>
        </div>
    )
}
export default SelectedTableHeader