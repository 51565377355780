import { useState, useEffect, useContext } from 'react'
import { OrderStates, useClient } from 'okeoke.client'
import { Link } from 'react-scroll'
import { MainContainerContext } from "contexts/MainContainerProvider"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { MainContext } from "contexts/MainContext"
import Header from './Header'
import useMultilang from "intl/useMultilang"
import TableList from './TableView/TableList'
import SelectedTableHeader from './TableView/SelectedTableHeader'
import ExternalPaymentPanel from './ExternalPaymentPanel'
import { useParams } from 'react-router-dom'
import OrderCardContainer from './OpenOrders/OrderCardContainer'
import TableView from './TableView/TableView'

function OrderManager(props) {

    const { readOnly, deviceType } = props
    const { setHeader, orders, orderObjects } = useContext(MainContainerContext)
    const { selectedExternalOrders, addToExternalOrders, externalOrderPayment, removeExternalOrder } = useContext(SelectedOrderContext)
    const { featureList } = useContext(MainContext)
    const { socketAuthenticated } = useClient()
    const [pingedOrderUUID, setPingedOrderUUID] = useState("")
    const { getT } = useMultilang()
    const [activeView, setActiveView] = useState(localStorage['selectedOrderManagerView'] || "openOrders")
    const [orderList, setOrderList] = useState([])
    const [selectedTableUUID, setSelectedTableUUID] = useState("")
    const [orderView, setOrderView] = useState(false)
    const { id } = useParams()

    useEffect(()=> {
        if(typeof setHeader === "function") {
            setHeader(
            <Header 
                setView={handleViewSet} 
                activeView={activeView}
                externalOrderPayment={externalOrderPayment}
            />)
        }     
        return () => setHeader(null)
        // eslint-disable-next-line
    },[activeView, featureList, externalOrderPayment])

    useEffect(() => {
        if(activeView === "openOrders") setOrderList(Object.values(orders || {}))
        if(activeView === "tableManager" && featureList?.tableManager === 1) {
            let ordersArray = []
            for(let order of Object.values(orders || {})) {
                if(order.tableUUID === selectedTableUUID) ordersArray.push(order)
            }
            setOrderList(ordersArray)
        }
        if(activeView === "needToPay") {
            let ordersArray = []
            for(let order of Object.values(orders || {})) {
                if(order.paymentsNeeded !== 0) ordersArray.push(order)
            }
            setOrderList(ordersArray)
        }
    }, [orders, activeView, featureList, selectedTableUUID])

    useEffect(() => {
        if(id != null) {
            handleViewSet("tableManager")
            handleSelectTable(id)
        }
        // eslint-disable-next-line
    }, [id])

    const handleViewSet = (viewName) => {
        localStorage['selectedOrderManagerView'] = viewName
        setActiveView(viewName)
    }

    const handleSelectTable = (tableUUID) => {
        setSelectedTableUUID(selectedTableUUID === tableUUID ? "" : tableUUID)
    }
    
    return (
        <>
        <div className={`external-payment-panel ${Object.values(selectedExternalOrders || {}).length > 0 ? "open" : "closed"}`}><ExternalPaymentPanel /></div>
        <div className={`order-view-content ${deviceType !== "orderManager" ? "" : "padding-left"} ${Object.values(selectedExternalOrders || {}).length > 0 ? "panel-open" : "panel-closed"}`}>
            {!socketAuthenticated && <div className="hidden quickAppear offline-container">{getT(`openOrders.offlineMode`)}</div>}
            {featureList?.tableManager === 1 && <div className={`table-list ${activeView === "tableManager" ? "open" : "closed"}`}>
                <TableList handleSelectTable={handleSelectTable} selectedTableUUID={selectedTableUUID} orders={orders} />
            </div>}
            <div className="order-view-right-side">
                {activeView !== "tableManager" && <>                
                    <div className="order-view-top-bar">
                        {orderList.map(order => 
                            <Link key={order.uuid} to={order.uuid} spy={true} smooth={true} containerId="order-card-container" horizontal={true} offset={-10}
                                onClick={() => { setPingedOrderUUID(order.uuid) }}
                                className={`order-view-top-bar-element ${order.orderState >= OrderStates.DONE ? "ready" : ""} `}>
                                <div className="order-number">
                                    {order.orderNumber}
                                </div>
                                <div className="order-state">
                                    {getT(`openOrders.orderCard.orderState-${order.orderState}`)}
                                </div>
                            </Link>
                        )}
                    </div>
                    <OrderCardContainer 
                        orderList={orderList}
                        readOnly={readOnly}
                        pingedOrderUUID={pingedOrderUUID}
                        setPingedOrderUUID={setPingedOrderUUID}
                        addToExternalOrders={addToExternalOrders}
                        selectedExternalOrders={selectedExternalOrders}
                        externalOrderPayment={externalOrderPayment}
                        removeExternalOrder={removeExternalOrder}
                    />
                </>}
                {activeView === "tableManager" && <>
                    <SelectedTableHeader 
                        selectedTableUUID={selectedTableUUID} 
                        orders={orderList} 
                        addToExternalOrders={addToExternalOrders} 
                        orderView={orderView}
                        setOrderView={setOrderView}
                    />                
                    {orderView && 
                        <OrderCardContainer 
                            orderList={orderList}
                            readOnly={readOnly}
                            pingedOrderUUID={pingedOrderUUID}
                            setPingedOrderUUID={setPingedOrderUUID}
                            addToExternalOrders={addToExternalOrders}
                            selectedExternalOrders={selectedExternalOrders}
                            externalOrderPayment={externalOrderPayment}
                            removeExternalOrder={removeExternalOrder}
                        />
                    }
                    {!orderView && 
                        <TableView orderList={orderList} selectedTableUUID={selectedTableUUID} orderObjects={orderObjects} />
                    }
                </>}
            </div>
        </div>
        </>
    )
}
export default OrderManager