import { useContext } from "react"
import { useDiscounts, useClient } from "okeoke.client"
import { useState } from "react"
import useMultilang from "intl/useMultilang"
import { MainContext } from "contexts/MainContext"
import LoadSvg from "components/LoadSvg-v2"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import NotAvailable from "components/NotAvailable"

function DiscountModal(props) {

  const { popModal } = props

  const { setDiscount, setItemDiscount, unsetDiscount, orderItemsAssembled } = useContext(SelectedOrderContext)
  const { featureList } = useContext(MainContext)
  const { discounts } = useDiscounts()
  const { getName } = useClient()
  const [selectedOrderItem, setSelectedOrderItem] = useState(null)
  const { getT } = useMultilang()

  const handleSelect = (orderItem) => {
    if(selectedOrderItem?.orderItemUUID === orderItem?.orderItemUUID) {
      setSelectedOrderItem(null)
    } else {
      setSelectedOrderItem(orderItem)
    }
  }

  const selectDiscount = (id) => {
    if(selectedOrderItem) {
      setItemDiscount(id, [selectedOrderItem?.orderItemUUID]);
    } else {
      setDiscount(id, null)
    }
  }

  const removeItemDiscount = (id, orderItemUUID) => {
    setItemDiscount(id, orderItemUUID, 0)
  }
  
  const removeAllDiscount = () =>{
    for(let discount of Object.values(discounts||{})){
      unsetDiscount(discount.id)
    }
  }

  const itemsArray = Object.values(orderItemsAssembled || {}).filter(orderItem => orderItem?.count > 0)

  if(!featureList.localDiscounts) return <NotAvailable />

  return (
    <div className="discount-modal-root">
      <div className="header">
        <div className="back-icon" onClick={popModal}>
            <LoadSvg name={"ArrowLeftIcon"} />
          </div>
        <div className="header-text" onClick={popModal}>{getT("orderItem.view.back")}</div>
        <div className="title">{getT("discountModal.header")}</div>
      </div>
      <div className="body">
        <div className="item-list">
          <div className="title">{getT("discountModal.orderItems")}</div>
          <div className="item-rows">
            {itemsArray.length === 0 && getT("discountModal.noItems")}
            {itemsArray.map(orderItem => 
              <div key={orderItem.orderItemUUID} className="item-row">
                <div 
                  className={`name ${orderItem.orderItemUUID === selectedOrderItem?.orderItemUUID ? "selected" : ""}`}
                  onClick={() => handleSelect(orderItem)}
                >
                  <div className="inner-bg"></div>
                  {getName(orderItem.name)}
                </div>
                <div className="discounts-container">
                  {orderItem.discounts
                  .filter(discount => discount.type === 3)
                  .map((discount, index) =>
                    <div key={index} className="discount-container hidden appear">
                      <div key={index} className={`discount-name`}>
                        {`${getName(discount?.name)} (${discounts?.[discount?.id]?.discountPercent} %)`}
                      </div>
                      <div className="bin-icon"onClick={() => removeItemDiscount(discount.id, orderItem.orderItemUUID)}>
                        <LoadSvg name="BinIcon"/>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="discount-list">
          <div className="title">{getT("discountModal.discounts")}</div>
          <div className="discounts">
            {Object.values(discounts || {})
            .map((discount, index) => 
                <div 
                  key={index} 
                  onClick={() => selectDiscount(discount?.id)}
                  className={`discount-btn ${ 
                    (selectedOrderItem ? (
                        (!discount.excludeSizes?.[selectedOrderItem?.selectedSizeUUID]) && 
                        (discount.includeSizes == null || discount.includeSizes?.[selectedOrderItem?.selectedSizeUUID])) : 
                    true) ? "" : "not-available"}`}
                >
                  {getName(discount?.name)}
                </div>
            )}
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="btn btn-warning" onClick={removeAllDiscount}>
          <LoadSvg name="BinIcon" className="bin" />
          {getT("discountModal.removeAllDiscount")}
        </div>
        <div className="btn btn-save" onClick={popModal}>{getT("discountModal.save")}</div>
      </div>
    </div>
  )
}
export default DiscountModal