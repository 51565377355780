import React, { useEffect, useContext } from "react";
import LoadSvg from "components/LoadSvg-v2";
import { ModalContext } from "contexts/ModalContext";
import { useFloating, flip, autoUpdate } from "@floating-ui/react-dom";

function Modal(props) {
  const {
    openCallback,
    closeCallback,
    onClickLayout,
    className,
    closeIcon,
    style,
    parentRef,
  } = props;
  const { popModal } = useContext(ModalContext);

  const { x, y, reference, floating, strategy, update, refs } = useFloating({
    // placement: "bottom-start",
    middleware: [flip()],
  });

  useEffect(() => {
    reference(parentRef?.current);
    // eslint-disable-next-line
  }, [parentRef]);


  useEffect(() => {
    return autoUpdate(
      refs.reference.current,
      refs.floating.current,
      update
    );
  }, [refs.reference, refs.floating, update]);

  useEffect(() => {
    if (typeof openCallback === "function") openCallback();
    return () => {
      if (typeof closeCallback === "function") closeCallback();
    };
    // eslint-disable-next-line
  }, []);

  const handleClickLayout = () => {
    if (typeof onClickLayout === "function") onClickLayout();
  };

  return (
    <div
      className={`modal modal-appear-effect ${
        className != null ? className : ""
      }`}
    >
      <div className="modal-bgLayout" onClick={handleClickLayout}></div>
      <div
        className="modal-content"
        ref={floating}
        style={{
          ...style?.modalContent,
          position: strategy,
          top: y ?? "",
          left: x ?? "",
        }}
      >
        {closeIcon != null && (
          <div className="close-icon" onClick={popModal}>
            <LoadSvg name="closeIcon2" />
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
}

export default Modal;
