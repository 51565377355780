import { useEffect, useContext } from 'react'
import LoadSvg from 'components/LoadSvg-v2'
import { useDevice, useLocation, useOkeFrame, useClient } from "okeoke.client"
import { isMobileOnly } from 'react-device-detect'
import { InputHandlerContext } from "contexts/InputHandlerProvider"
import { TutorialContext } from 'contexts/TutorialContext'
import LoginPanel from './LoginPanel'
import LoginMessage from './LoginMessage'
import { useNavigate } from 'react-router-dom'

function Login() {
    
    const { loginUser, deviceName, localUsers, deviceParams } = useDevice()
    const { demoMode, brandID } = useClient()
    const { setMachineProvidedFunction } = useContext(InputHandlerContext)
    const { setContent, tutorialOn } = useContext(TutorialContext)

    const { location } = useLocation()
    const { sendMessageToWebView, frameType } = useOkeFrame()
    const navigate = useNavigate()

    useEffect(() => {
        setMachineProvidedFunction((e) => handleTag(e))
        setContent("center", 
            <>
                <LoginMessage />
                <div className="hidden appear login-container">
                    <LoginPanel 
                        location={location}
                        deviceName={deviceName}
                        localUsers={localUsers} 
                        submitPin={submitPin}
                        visible={true}
                        brandID={brandID}
                        deviceParams={deviceParams}
                    />
                </div>
            </>
        )
        return () => setMachineProvidedFunction(null)
        // eslint-disable-next-line
    }, [location, deviceName, localUsers])

    useEffect(() => {
        if(demoMode) loginUser(localUsers[0].pinCode)
        // eslint-disable-next-line
    }, [demoMode, localUsers])

    const handleTag = (input) => {
        let id = replaceAll(input, {"ö": 0})
        loginUser(null, id)
      }
    
      const replaceAll = (str, mapObj) => {
        if(str == null) return null
        let re = new RegExp(Object.keys(mapObj).join("|"),"gi")
    
        return str.replace(re, function(matched){
            return mapObj[matched.toLowerCase()]
        })
      }

    const exitApp = () => {
        sendMessageToWebView({ action: 'exitApp' })
    }

    const submitPin = (pin) => {
        if(pin !== "") {
            navigate("/")
            loginUser(pin)
            setContent("center", null)
        }
    }

    return(
        <div className="hidden appear login-outer-container">
            <div className="left-side">
                <div className="logo-container">
                    <LoadSvg name="barSoftLogo" />
                </div>
                <div className="login-img-container floating-animation">
                    <img src="/assets/businessman.png" alt="businessman" />
                </div>
            </div>
            <div className="hidden appear login-container">
                {isMobileOnly && <LoadSvg name="barSoftLogo" />}
                {/* <div id="login-oktopos-icon">{getSvgByName('OktoposIcon')}</div> */}
                <LoginPanel 
                    location={location}
                    deviceName={deviceName}
                    localUsers={localUsers} 
                    submitPin={submitPin}
                    visible={isMobileOnly || !tutorialOn}
                    brandID={brandID}
                    deviceParams={deviceParams}
                />
            </div>
            {frameType === "okeElectron" && <div className="btn exit-btn" onClick={exitApp} ><LoadSvg name="exit"/></div>}
        </div>
    )
}

export default Login