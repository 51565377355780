import { useContext, useState } from "react"
import { PaymentContext } from "contexts/PaymentContext"
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import { ModalContext } from "contexts/ModalContext"
import NumberFormat from "react-number-format"
import { v4 as uuidv4 } from "uuid"
import StornoPaymentModal from "./StornoPaymentModal"
import { useMain, useOkeFrame } from "okeoke.client"
import { isMobileOnly } from "react-device-detect"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { toast } from "react-toastify"

function Methods(props) {

  const { hidden } = props

  const { popModal } = useContext(ModalContext)
  const { getT } = useMultilang()
  
  const {
    payments,
    handleAddPayment,
    serviceFee,
    devicePaymentAgents,
    paymentsTotal,
    bankCardPaymentInProgress,
    addPaymentInProgress,
    loading,
    externalPayment,
    externalTipAmount
  } = useContext(PaymentContext)

  const { 
    paymentsNeeded, 
    sumPrice, 
    discountAmount, 
    tipAmount, 
    packagingPrice, 
    deliveryCost,
    refundVipMoney,
    selectedOrdersSum 
  } = useContext(SelectedOrderContext)

  const [localModal, setLocalModal] = useState()
  //console.log(payments)

  const getData = (message) => {
    //console.log(message)
  }

  const { messageSecondary } = useMain(getData)
  const { sendMessageToWebView } = useOkeFrame()



  const backToSell = () => {
    messageSecondary({ action: "updateStatus", data: "sell" })
    messageSecondary({ action: "tipChange", data: true })
    if(typeof popModal === "function") popModal()
  }

  const handleStorno = (selectedPaymentAgent, paymentUUID) => {
    if(selectedPaymentAgent.agentType !== "vip") {
      let params = {
        paymentAgentID: selectedPaymentAgent.paymentAgentID, 
        amount: 0, 
        transactionID: uuidv4(),
        data: {},
        stornoUUID: paymentUUID,
        agentType: selectedPaymentAgent.agentType
      }
      handleAddPayment(params)
    } else {
      refundVipMoney(paymentUUID).then(res => {
        if(!res.success) toast(getT("payment.vip.stornoError"))
      })
    }
    setLocalModal(null)
  }

  const openStornoModal = (payment) => {
    if (payment.amount === 0) return null
    setLocalModal(
      <StornoPaymentModal 
        amount={payment.amount} 
        clearLocalModal={() => setLocalModal(null)} 
        devicePaymentAgents={devicePaymentAgents} 
        paymentAgentID={payment.paymentAgentID}
        paymentUUID={payment.uuid}
        handleStorno={handleStorno}
      />
    )
  }

  const exitApp = () => {
    popModal()
    sendMessageToWebView({ action: 'exitApp' })
  }

  const filteredPayments = (payments || []).filter(payment => (!payment.deleted && payment.stornoPaymentUUID == null))

  if(hidden) return null

  return (
    <>
      {localModal}
      {bankCardPaymentInProgress && <div className="hidden quickAppear printing-alert">{getT("alert.bankCardTransactionInProgress")}<div className="btn exit-btn" onClick={exitApp} ><LoadSvg name="exit"/></div></div>}
      {addPaymentInProgress && <div className="hidden quickAppear printing-alert">{getT("alert.addPaymentInProgress")}<div className="btn exit-btn" onClick={exitApp} ><LoadSvg name="exit"/></div></div>}
      {loading && <div className="hidden quickAppear printing-alert">{getT("alert.loading")}<div className="btn exit-btn" onClick={exitApp} ><LoadSvg name="exit"/></div></div>}
      <div className="hidden quickAppear payment-methods-container">
        {!isMobileOnly && <div className="payment-methods-header" onClick={backToSell}>
          <div className="back-icon" >
            <LoadSvg name={"ArrowLeftIcon"} />
          </div>
          <div className="header-text">{getT("payment.view.backToSell")}</div>
        </div>}
        <div className="payment-methods-body">
          <div className="order-info">
            {getT("payment.view.title")}
            {/* <div className="order-identifier">{orderIdentifier === "" ? getT("sell.basket.order") : orderIdentifier}</div>
            <div className="order-take-away">({takeAway ? getT("sell.basket.takeAway") : getT("sell.basket.eatIn")})</div> */}
          </div>
          <div className="sum-row sum-price">
            <span>{getT("payment.view.sumPrice")}:</span>
            <NumberFormat
              value={externalPayment ? selectedOrdersSum?.sumDiscountPrice : sumPrice}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
          <div className="sum-row packaging-price">
            <span>{getT("payment.view.packagingPrice")}:</span>
            <NumberFormat
              value={externalPayment ? selectedOrdersSum?.sumPackagingPrice : packagingPrice}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
          <div className="sum-row delivery-cost">
            <span>{getT("payment.view.deliveryCost")}:</span>
            <NumberFormat
              value={externalPayment ? selectedOrdersSum?.sumDeliveryCost : deliveryCost}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
          <div className="sum-row service-fee-total">
            <span>{getT("payment.view.serviceFeeTotal")}:</span>
            <NumberFormat
              value={(externalPayment ? selectedOrdersSum?.sumServiceFee : serviceFee?.total) || 0}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
          <div className="sum-row tipp-amount">
            <span>{getT("payment.view.tippAmount")}:</span>
            <NumberFormat
              value={(externalPayment ? externalTipAmount : tipAmount) || 0}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
          <div className="sum-row discount-amount">
            <span>{getT("payment.view.totalDiscount")}:</span>
            <NumberFormat
              value={-(discountAmount)}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
          <div className="sum-row payments-needed">
            <span>{getT("payment.view.paymentsNeeded")}:</span>
            <NumberFormat
              value={externalPayment ? (selectedOrdersSum?.sumPaymentsNeeded + externalTipAmount) : paymentsNeeded}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
          {(!externalPayment) && <div className="sum-row total-paid">
            <span>{getT("payment.view.paid")}:</span>
            <NumberFormat
              value={paymentsTotal}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>}
          {(!externalPayment) && <div className="details">
            <div className="payments-title">{getT("payment.view.payments")}</div>
            <div className="payments">
              {filteredPayments.length < 1 && getT("payment.view.noPaymentsAdded")}
              {filteredPayments.map((payment, index) =>
                <div className="methods-body-row hidden appear" key={index}>
                  <div>{getT(`payment.view.${payment?.agentType}`)}</div>
                  <div className="value">
                    <NumberFormat
                      value={payment?.amount}
                      decimalSeparator=","
                      suffix=" Ft"
                      thousandSeparator="."
                      displayType="text"
                    />
                    {payment.amount > 0 && <LoadSvg name={"BinIcon"} className="bin-icon" onClick={() => openStornoModal(payment)} />}
                    {payment.amount <= 0 &&  <div className="bin-icon"></div>}
                  </div>
                </div>
                )
              }
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}

export default Methods
