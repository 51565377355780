import { useEffect, useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import { isMobileOnly } from "react-device-detect"

function BrandSettings(props) {

    const { checkPin, setActiveScreen, posToken } = props

    const [brandList, setBrandList] = useState({})
    const [deviceList, setDeviceList] = useState({})
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [selectedDevice, setSelectedDevice] = useState(null)
    const [loading, setLoading] = useState(true)
    const [devicePin, setDevicePin] = useState(null)

    /* #1 Select brand */
    useEffect(() => {
        if(posToken != null) {
            try {
                let axiosInstance = axios.create({
                    timeout: 15000,
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + posToken
                    }
                })
                axiosInstance.get("https://ipanel.okeoke.io/api/v1/brand/brands").then(res => {
                    if(res.data.success) {
                        let obj = {}
                        for(let brand of res.data.data) {
                            obj[brand.id] = brand
                        }
                        if(localStorage['autoActivateBrand'] != null) {
                            setSelectedBrand(obj?.[localStorage['autoActivateBrand']])
                        } else {
                            setSelectedBrand(res.data.data[0])
                        }
                        setBrandList(obj)
                    } else {
                        toast.error("Hiba történt!")
                    }
                })
            } catch(e) {
                toast.error("Hiba történt!")
                console.log(e)
            }
        }
    },[posToken])

    /* #2 Select devices */
    useEffect(() => {
        if(selectedBrand != null && posToken != null) {
            try {
                let axiosInstance = axios.create({
                    timeout: 15000,
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + posToken
                    }
                })
                axiosInstance.get(`https://ipanel.okeoke.io/api/v1/brand/devices/basic?brandID=${selectedBrand.id}&filter={"deviceTypes": [2, 3]}`).then(res => {
                    if(res.data.success) {
                        let obj = {}
                        for(let device of res.data.data) {
                            if(device.enabled && (device?.state == null || device?.state?.connected === false)) obj[device.uuid] = device
                        }
                        (res.data.data[0]?.state == null || res.data.data[0]?.state?.connected === false) ? setSelectedDevice(res.data.data[0]) : setSelectedDevice(null)
                        setDeviceList(obj)
                    } else {
                        toast.error("Hiba történt!")
                    }
                    if(localStorage['autoActivateBrand'] == null) setLoading(false)
                })
            } catch(e) {
                toast.error("Hiba történt!")
                console.log(e)
                setLoading(false)
            }
        }
    }, [selectedBrand, posToken])

    /* #3 If brand & device selected & auto activate on, generate device pin */
    useEffect(() => {
        if(localStorage['autoActivateBrand'] != null && selectedDevice != null && selectedBrand != null) {
            generateDevicePin()
        }
        // eslint-disable-next-line
    }, [selectedDevice, selectedBrand])

    /* #4 Device PIN generated, auto activate on -> check pin and set first login */
    useEffect(() => {
        if(devicePin != null) {
            if(localStorage['autoActivateBrand'] != null) {
                try {
                    let axiosInstance = axios.create({
                        timeout: 15000,
                        headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + posToken
                        }
                    })
                    axiosInstance.get(`https://ipanel.okeoke.io/api/v1/global/users/app?appName=brands&appID=${localStorage['autoActivateBrand']}`).then(res => {
                        localStorage['firstLoginPin'] = (res.data.data[0]?.pinCode)
                        checkPin(devicePin)
                        localStorage.removeItem("pos-token")
                        localStorage.removeItem("autoActivateBrand")
                    })
                } catch(e) {
                    toast.error("Hiba történt!")
                    console.log(e)
                }
            } else {
                checkPin(devicePin)
                localStorage.removeItem("pos-token")
            }
        }
        // eslint-disable-next-line
    }, [devicePin])

    const generateDevicePin = () => {
        try {
            let axiosInstance = axios.create({
                timeout: 15000,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + posToken
                }
            })
            let body = {
              brandID: selectedBrand.id,
              locationUUID: selectedDevice.locationUUID,
              deviceUUID: selectedDevice.uuid,
              deviceType: selectedDevice.deviceType
            }
            axiosInstance.post(`https://ipanel.okeoke.io/api/v1/auth/generateDevicePinCode`, body).then(res => {
                if(res.data.success) {
                    setActiveScreen("")
                    setDevicePin(res.data.data)
                } else {
                    toast.error("Hiba történt!")
                    setLoading(false)
                }
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    if(loading) return <div className={`hidden appear device-login-brand-settings ${isMobileOnly ? "isMobile" : ""}`}></div>

    return(
        <>
        <div className="brand-settings-title">Eszköz aktiválás</div>
        <div className={`hidden appear device-login-brand-settings ${isMobileOnly ? "isMobile" : ""}`}>
            {Object.values(brandList || {}).length > 1 && 
            <div className="hidden appear brand-list">
                <div className="title">Brandek</div>
                {Object.values(brandList || {}).map((brand, index) => 
                    <div 
                        key={index} 
                        className={`brand-button ${brand.id === selectedBrand?.id ? "selected" : ""}`}
                        onClick={() => setSelectedBrand(brand)}
                    >
                        {brand.brandName}
                    </div>
                )}
            </div>}
            <div className="hidden appear device-list">
                <div className="title">Eszközök</div>
                {Object.values(deviceList || {}).length === 0 && <p>Nincs egy eszköz sem.</p>}
                {Object.values(deviceList || {}).map((device, index) => 
                    <div 
                        key={index} 
                        className={`device-button ${device.uuid === selectedDevice?.uuid ? "selected" : ""}`}
                        onClick={() => setSelectedDevice(device)}
                    >
                        {device.deviceName}
                    </div>
                )}
            </div>
            <div className="hidden appear selected-device">
                {selectedDevice == null && <p>Nincs kiválasztva eszköz.</p>}
                {selectedDevice != null && <div className="device-name">{selectedDevice?.deviceName}</div>}
                {selectedDevice != null && <div className="btn" onClick={generateDevicePin}>Aktiválom</div>}
            </div>
        </div>
        </>
    )
}
export default BrandSettings