import { useEffect, useState } from "react"
import Actions from "./Actions"
import { useContext } from "react"
import { WizardContext } from "./WizardContext"
import useLocationsConfigApi from "apis/locations/useLocationsConfigApi"
import useMultilang from "intl/useMultilang"
import { toast } from "react-toastify"

function TippType() {

    const { activeStep, goToPrev, goToNext } = useContext(WizardContext)
    const { getLocationConfig, setLocationConfig } = useLocationsConfigApi()
    const { getT } = useMultilang()

    const [tippType, setTippType] = useState(null)
    const [localData, setLocalData] = useState(null)

    useEffect(() => {
        getLocationConfig().then(res => {
            if(res.success) {
                setLocalData(res.data)
                if(res.data?.localConfig?.tippType != null) setTippType(res.data?.localConfig?.tippType)
            }
        })
        // eslint-disable-next-line
    }, [])

    const handleClickPrev = () => {
        goToPrev()
    }

    const handleClickNext = () => {
        saveLocalConfig()
    }

    const saveLocalConfig = () => {
        let newValues = {...localData, localConfig: {...localData.localConfig, tippType}}
        setLocationConfig(newValues).then(res => {
            if(res.success) {
                goToNext()
            } else {
                toast.error(getT("error"))
            }
        })
    }

    if(activeStep !== "tippType") return null

    return(
        <>
            <div className="wizard-step">
                <div className="title">{getT("settingWizard.tippType.title")}</div>
                <div className="tipp-type-cards">
                    <div className={`tipp-type-card ${tippType === 0 ? "selected" : ""}`} onClick={() => setTippType(0)}>
                        <div className="title">{getT("settingWizard.tippType.title0")}</div>
                        <div className="text">{getT("settingWizard.tippType.text0")}</div>
                    </div>
                    <div className={`tipp-type-card ${tippType === 1 ? "selected" : ""}`} onClick={() => setTippType(1)}>
                        <div className="title">{getT("settingWizard.tippType.title1")}</div>
                        <div className="text">{getT("settingWizard.tippType.text1")}</div>
                    </div>
                    <div className={`tipp-type-card ${tippType === 2 ? "selected" : ""}`} onClick={() => setTippType(2)}>
                        <div className="title">{getT("settingWizard.tippType.title2")}</div>
                        <div className="text">{getT("settingWizard.tippType.text2")}</div>
                    </div>
                </div>
            </div>
            <Actions onClickPrev={handleClickPrev} onClickNext={handleClickNext} />
        </>
    )
}
export default TippType