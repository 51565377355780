import Item from "views/Payment/Item"
import useMultilang from "intl/useMultilang"
import { useState, useContext, useEffect } from "react"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { useNavigate } from "react-router-dom"
import LoadSvg from "components/LoadSvg-v2"
import Modal from "components/Modal/Modal"
import Payment from "views/Payment"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import NumberFormat from "react-number-format"
import { MainContainerContext } from "contexts/MainContainerProvider"
import { OrderStates, OrderStatus } from "okeoke.client"

function IdentifierContainer(props) {

    const { getT } = useMultilang()

    const { orderIdentifier, orderItems, selectedTableUUID, orders } = props

    const [showDetails, setShowDetails] = useState(localStorage['showItemDetailsInBasket'])

    const navigate = useNavigate()
    
    const { setOrderIdentifier, setTable, addToExternalOrders, clearExternalOrders, selectOrderWithoutSave } = useContext(SelectedOrderContext)
    const { addModal } = useContext(ModalContext)
    const { orderObjects } = useContext(MainContainerContext)

    const [controllerVisible, setControllerVisible] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [canPayOrder, setCanPayOrder] = useState(null)

    useEffect(() => {
        let anyItem = false
        for(let item of (orderItems?.[orderIdentifier] || [])) {
            if(item.count > (item?.payment?.count || 0)) anyItem = true
        }
        setCanPayOrder(anyItem)
    }, [orderItems, orderIdentifier])

    const toggleController = (order) => {
        setSelectedOrder(order)
        setControllerVisible(!controllerVisible)
    }

    const addItem = () => {
        if(orderIdentifier !== "noId") {
            setOrderIdentifier(orderIdentifier)
        }
        setTable(selectedTableUUID)
        localStorage["lastTableUUID"] = selectedTableUUID
        navigate("/")
    }

    const payOrders = () => {
        addToExternalOrders(orders?.[orderIdentifier].filter(order => order.paymentsNeeded > 0 && order?.paymentState?.paymentTypeAllowed !== 1))
        handleExternalPay()
    }

    const payOrder = (order) => {
        if(order?.paymentState?.paymentTypeAllowed === 0 || order?.paymentState?.paymentTypeAllowed === 2) {
            addToExternalOrders([order])
            handleExternalPay()
        } else {
            handlePay(order.uuid)
        }
    }

    const handleExternalPay = () => {
        addModal(
          <Modal key={uuidv4()} className="payment-modal" closeCallback={clearExternalOrders}>
            <Payment external={true} orderObjects={orderObjects} />
          </Modal>
        )
    }

    const handlePay = (orderUUID) => {
        selectOrderWithoutSave(orderUUID)
        addModal(
          <Modal key={uuidv4()} className="payment-modal">
            <Payment />
          </Modal>
        )
      }

    const toggleShowItemDetails = () => {
        setShowDetails(showDetails ? false : "1")
        showDetails ? localStorage.removeItem("showItemDetailsInBasket") : localStorage["showItemDetailsInBasket"] = "1"
    }

    const addOrderTimeEstimate = (orderUUID, minutes) => {
        if(typeof orderObjects?.[orderUUID]?.statuses?.addStatus === "function") {
            orderObjects?.[orderUUID]?.statuses?.addStatus(OrderStatus.ORDER_TIME_ESTIMATE, { minutes })
        }
    }

    const setOrderReady = (orderUUID) => {
        if(typeof orderObjects?.[orderUUID]?.statuses?.addStatus === "function") {
            orderObjects?.[orderUUID]?.statuses?.addStatus(OrderStatus.ORDER_DONE, {})
        }
    }

    const setOrderSeen = (orderUUID) => {
        if(typeof orderObjects?.[orderUUID]?.statuses?.addStatus === "function") {
            orderObjects?.[orderUUID]?.statuses?.addStatus(OrderStatus.ORDER_RECEIVED, {})
        }
    }

    const setOrderCloseSuccess = (orderUUID) => {
        if(typeof orderObjects?.[orderUUID]?.statuses?.addStatus === "function") {
            orderObjects?.[orderUUID]?.statuses?.addStatus(OrderStatus.ORDER_CLOSED_SUCCESS, {})
        }
    }

    return(
        <div className="order-identifier-container">
            <div className={`controller ${controllerVisible ? "visible" : "not-visible"}`} onClick={() => toggleController(null)}>
                {selectedOrder == null && <>
                    {canPayOrder && <div className="btn" onClick={payOrders}>{getT("tableView.payAll")}</div>}
                    <div className="btn" onClick={addItem}>{getT("tableView.add")}</div>
                </>}
                {selectedOrder != null && <>
                    <div className="order-number">{selectedOrder?.orderNumber}</div>
                    {selectedOrder?.paymentsNeeded > 0 && <div className="btn" onClick={() => payOrder(selectedOrder)}>{getT("tableView.pay")}</div>}
                    {selectedOrder?.orderState === OrderStates.INSERTED && <div className="btn btn-ready" onClick={() => setOrderSeen(selectedOrder.uuid)}>{getT("orderController.orderSeen")}</div>}
                    {selectedOrder?.orderState === OrderStates.SEEN && <>
                    <div className="btn btn-estimate" onClick={() => addOrderTimeEstimate(selectedOrder.uuid, 5)}>
                        <div className="number">5</div> 
                        <div className="text">{getT("orderedItem.ticket.minute")}</div>
                    </div>
                    <div className="btn btn-estimate" onClick={() => addOrderTimeEstimate(selectedOrder.uuid, 10)}>
                        <div className="number">10</div> 
                        <div className="text">{getT("orderedItem.ticket.minute")}</div> 
                    </div>
                    <div className="btn btn-estimate" onClick={() => addOrderTimeEstimate(selectedOrder.uuid, 20)}>
                        <div className="number">20</div> 
                        <div className="text">{getT("orderedItem.ticket.minute")}</div> 
                    </div>
                    <div className="btn btn-estimate" onClick={() => addOrderTimeEstimate(selectedOrder.uuid, 30)}>
                        <div className="number">30</div> 
                        <div className="text">{getT("orderedItem.ticket.minute")}</div> 
                    </div>
                    </>}
                    {selectedOrder?.orderState === OrderStates.PREPARING && <div className="btn btn-ready" onClick={() => setOrderReady(selectedOrder.uuid)}>{getT("orderController.orderReady")}</div>}
                    {selectedOrder?.orderState === OrderStates.DONE && selectedOrder?.paymentsNeeded === 0 && <div className="btn btn-done" onClick={() => setOrderCloseSuccess(selectedOrder.uuid)}>{getT("orderedItem.ticket.done")}</div>}
                </>}
            </div>    
            <div className="header">
                <div className="identifier" onClick={() => toggleController(null)}>{orderIdentifier === "noId" ? getT("tableView.noIdentifier") : orderIdentifier}</div>
                <div className="actions">
                    <div className={`action-btn ${showDetails ? "selected" : ""}`} onClick={toggleShowItemDetails}><div className="inner-bg"></div><LoadSvg name="details"/></div>
                    {canPayOrder && <div className="action-btn" onClick={payOrders}>
                        <LoadSvg name="dollar" />
                    </div>}
                    <div className="action-btn" onClick={addItem}>
                        <LoadSvg name="shoppingBag" />
                    </div>
                </div>
            </div>
            <div className="orders-container">
                {(orders?.[orderIdentifier] || []).length === 0 && <div className="no-items">{getT("tableView.noItems")}</div>}
                {(orders?.[orderIdentifier] || []).map(order => <div key={order.uuid} className={`order-container ${order.paymentsNeeded === 0 ? "payed" : ""}`} onClick={() => toggleController(order)}>
                    <div className="order-header">
                        <div className="order-number">
                            {`${order.orderNumber} ${(order.paymentsNeeded > 0 && order?.paymentState?.paymentTypeAllowed === 1) ? getT("tableView.paymentStarted") : ""}`}
                        </div>
                        <div className={`order-indicator ${order.orderState <= 10 ? "highlighted" : ""} ${order.orderState === 50 ? "preparing" : ""} ${order.orderState >= 60 ? "ready" : ""}`}></div>
                    </div>
                    <div className="items-container">{(order?.orderItems || []).map(item => 
                        <Item key={item.orderItemUUID} assembledItem={item} showDetails={showDetails} />
                    )}</div>
                </div> 
                )}
            </div>
            <div className="footer">
                <div className="label">
                    {getT("tableView.sumPaymentsNeeded")}
                </div>
                <NumberFormat
                    value={+orders?.[orderIdentifier].reduce((sum, a) => sum + +(a?.paymentsNeeded || 0), 0)}
                    decimalSeparator=","
                    suffix=" Ft"
                    thousandSeparator="."
                    displayType="text"
                />
            </div>
        </div>
    )

}
export default IdentifierContainer