import { createContext, useEffect, useState } from "react"
import { useItems } from "okeoke.client"
import useMultilang from "intl/useMultilang"

const CatalogContext = createContext()

function CatalogProvider(props) {
  const { categoryTree, categories, assembledItems, sizes, modifiers } = useItems()
  const [selectedCategUUID, setSelectedCategUUID] = useState(null)
  const [validCategs, setValidCategs] = useState([])
  const [disabledCategs, setDisabledCategs] = useState([])
  const [categoryAssembledItems, setCategoryAssembledItems] = useState([])
  const { language } = useMultilang()

  const sortFunction = (a, b) => assembledItems[a.uuid].name?.[language] > assembledItems[b.uuid].name?.[language] ? 1 : -1

  useEffect(() => {
    if(categories?.[selectedCategUUID]?.items != null && assembledItems != null) {
      let itemsArray = categories?.[selectedCategUUID]?.items
      .map(item => assembledItems?.[item?.uuid])
      .filter(item => item?.enabled)
      .filter(item => assembledItems?.[item?.uuid]?.availability?.available)
      .sort(sortFunction)
      setCategoryAssembledItems(itemsArray)
    }
    // eslint-disable-next-line
  }, [categories, selectedCategUUID, assembledItems])

  useEffect(() => {
    let newValidCategs = (categoryTree || []).filter(categ => (categories?.[categ.uuid]?.enabled && (categ.availability.availableItems > 0 || categ.availability.availableSubItems > 0)))
    let newDisabledCategs = (categoryTree || []).filter(categ => (!categories?.[categ.uuid]?.enabled))
    setValidCategs(newValidCategs)
    setDisabledCategs(newDisabledCategs)
  }, [categoryTree, categories])

  useEffect(() => {
    if(!selectedCategUUID && validCategs.length > 0) {
      setSelectedCategUUID(validCategs?.[0]?.uuid)
    }
  }, [validCategs, selectedCategUUID])

  const handleSelectCategory = (uuid) => {
    if(selectedCategUUID !== uuid) setSelectedCategUUID(uuid)
  }

  return (
    <CatalogContext.Provider
      value={{
        categoryTree, 
        categories, 
        assembledItems,
        sizes,
        modifiers,
        selectedCategUUID,
        handleSelectCategory,
        validCategs,
        disabledCategs,
        categoryAssembledItems,
        sortFunction
      }}
    >
      {props.children}
    </CatalogContext.Provider>
  )
}

export { CatalogProvider, CatalogContext }
