import { useEffect, useState } from "react"
import IdentifierContainer from "./IdentifierContainer"

function TableView(props) {

    const { orderList, selectedTableUUID, orderObjects } = props

    const [orders, setOrders] = useState({})
    const [orderItems, setOrderItems] = useState({})

    useEffect(() => {
        let objItems = { noId: [] }
        let obj = { noId: [] }
        for(let order of orderList) {
            if(order.orderIdentifier === "") {
                let orderItems = objItems.noId.concat(Object.values(orderObjects?.[order.uuid]?.assembledOrderItems || {}))
                let orderData = obj.noId.concat([{
                    uuid: order.uuid, 
                    orderNumber: order.orderNumber, 
                    orderIdentifier: "",
                    paymentsNeeded: order.paymentsNeeded,
                    orderState: order.orderState,
                    paymentState: order.paymentState,
                    orderItems: Object.values(orderObjects?.[order.uuid]?.assembledOrderItems || {})
                }])
                objItems = { ...objItems, noId: orderItems}
                obj = { ...obj, noId: orderData }
            } else {
                if(objItems?.[order.orderIdentifier] == null) {
                    objItems = { ...objItems, [order.orderIdentifier]: Object.values(orderObjects?.[order.uuid]?.assembledOrderItems || {}) }
                    obj = { ...obj, [order.orderIdentifier]: [{
                        uuid: order.uuid, 
                        orderNumber: order.orderNumber, 
                        orderIdentifier: order.orderIdentifier,
                        paymentsNeeded: order.paymentsNeeded,
                        orderState: order.orderState,
                        paymentState: order.paymentState,
                        orderItems: Object.values(orderObjects?.[order.uuid]?.assembledOrderItems || {})
                    }]}
                } else {
                    let orderItems2 = objItems?.[order.orderIdentifier].concat(Object.values(orderObjects?.[order.uuid]?.assembledOrderItems || {}))
                    let orderData2 = obj?.[order.orderIdentifier].concat([{
                        uuid: order.uuid, 
                        orderNumber: order.orderNumber, 
                        orderIdentifier: order.orderIdentifier,
                        paymentsNeeded: order.paymentsNeeded,
                        orderState: order.orderState,
                        paymentState: order.paymentState,
                        orderItems: Object.values(orderObjects?.[order.uuid]?.assembledOrderItems || {})
                    }])
                    objItems = { ...objItems, [order.orderIdentifier]: orderItems2}
                    obj = { ...obj, [order.orderIdentifier]: orderData2}
                }
            }
        }
        setOrderItems(objItems)
        setOrders(obj)
        // eslint-disable-next-line
    }, [orderList, orderObjects])

    return(
        <div className="table-view">
            {Object.keys(orderItems).map(orderIdentifier => 
                <IdentifierContainer 
                    key={orderIdentifier} 
                    orderIdentifier={orderIdentifier} 
                    orderItems={orderItems} 
                    orders={orders}
                    selectedTableUUID={selectedTableUUID}
                />
            )}
        </div>
    )
}
export default TableView