import { useContext } from 'react'
import { CatalogContext } from '../contexts/CatalogContext'

export default function useMultilang() {
    const {         
      categoryTree, 
      categories, 
      assembledItems,
      sizes,
      modifiers,
      selectedCategUUID,
      handleSelectCategory,
      validCategs,
      disabledCategs,
      categoryAssembledItems,
      sortFunction
     } = useContext(CatalogContext)

    return {
      categoryTree, 
      categories, 
      assembledItems,
      sizes,
      modifiers,
      selectedCategUUID,
      handleSelectCategory,
      validCategs,
      disabledCategs,
      categoryAssembledItems,
      sortFunction
    }
} 