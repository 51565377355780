import { useState, useContext } from "react"
import useMultilang from "intl/useMultilang"
import { toast } from 'react-toastify'
import Actions from "./Actions"
import { WizardContext } from "./WizardContext"
import { usePosPrinter } from "okeoke.client"
import usePrintersApi from "apis/usePrintersApi"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import PosPrinterSettings from "components/PosPrinterSettings"
//import { MainContext } from "contexts/MainContext"
import useDevicesApi from "apis/useDevicesApi"
import { useDevice } from "okeoke.client"

const defaultValues = { name: "LocalPrinter", commPort: 9100, commType: "net", printerType: "EPSON", commPath: "", maxChars: "48" }

function PosPrinter() {

    const { activeStep, goToPrev, goToNext } = useContext(WizardContext)
    //const { setReloadNeeded } = useContext(MainContext)

    const { getT } = useMultilang()
    const { printers } = usePosPrinter()

    const { setDevice } = useDevicesApi()
    const { deviceUUID } = useDevice()
    const { setPrinter } = usePrintersApi()
    const { localAppParams, changeLocalAppParam } = useContext(PersonalSettingsContext)

    const [localParams, setLocalParams] = useState(defaultValues)
    const [selectedOption, setSelectedOption] = useState("noPrinter")
    const [selectedNetworkPrinterUUID, setSelectedNetworkPrinterUUID] = useState(null)
    const [isPrivate, setIsPrivate] = useState(false)

    const handleClickPrev = () => {
        goToPrev()
    }

    const handleClickNext = () => {
        if(selectedOption === "noPrinter") goToNext()

        if(selectedOption === "localPrinter") {
            let valid = validate()
            if(valid) {
                addNewPrinter()
            }
        }

        if(selectedOption === "networkPrinter") {
            if(selectedNetworkPrinterUUID != null) {
                const device = {
                    uuid: deviceUUID,
                    deviceParams: {
                      posPrinter: { uuid: selectedNetworkPrinterUUID }
                    }
                }
                setDevice(device).then(res => {
                    console.log(res)
                    if(res.success) goToNext()
                })                
            } else {
                toast.error(getT("settingWizard.errors.selectPrinter"))
            }
        }
    }

    const addNewPrinter = () => {
        setPrinter({...localParams, deviceUUID: isPrivate ? deviceUUID : null}).then(res => {
            console.log(res)
            if(res.success) {
                setDevice({ posPrinter: { uuid: res.data.uuid } }).then(res => {
                    console.log(res)
                    if(res.success) goToNext()
                })                
            }
        })
    }

    const validate = () => {

        let validation = true

        if(localParams?.name == null || localParams?.name === "") {
            toast.error("Name required")
            validation = false
        }
        if(localParams?.commType == null) {
            toast.error("CommType required")
            validation = false
        }
        if(localParams?.commPath == null || localParams?.commPath === "") {
            toast.error("CommPath required")
            validation = false
        }
        if(localParams?.printerType == null) {
            toast.error("PrinterType required")
            validation = false
        }

        return validation
    }

    const changeFieldData = (e) => {
        setLocalParams({...localParams, [e.target.name]: e.target.value})
    }

    const checkOption = (name, value) => {
        changeLocalAppParam("behaviour", name, value)
    }

    if(activeStep !== "posPrinter") return null

    return(
        <>
        <div className="wizard-step">
            <div className="pos-printer-settings">
                <div className="title">{getT("settingWizard.posPrinter.title")}</div>
                <div className="outer-container">
                    <div className="settings-container">
                        <div className="choose">
                            <div className={`btn ${selectedOption === "noPrinter" ? "btn-highlighted" : ""}`} onClick={() => setSelectedOption("noPrinter")}>
                                {getT("settingWizard.posPrinter.noPrinter")}
                            </div>
                            <div className={`btn ${selectedOption === "localPrinter" ? "btn-highlighted" : ""}`} onClick={() => setSelectedOption("localPrinter")}>
                                {getT("settingWizard.posPrinter.localPrinter")}
                            </div>
                            <div className={`btn ${selectedOption === "networkPrinter" ? "btn-highlighted" : ""}`} onClick={() => setSelectedOption("networkPrinter")}>
                                {getT("settingWizard.posPrinter.networkPrinter")}
                            </div>
                        </div>
                        {selectedOption === "localPrinter" && 
                            <PosPrinterSettings 
                                localParams={localParams} 
                                changeFieldData={changeFieldData} 
                                setIsPrivate={setIsPrivate}
                                isPrivate={isPrivate}
                            />}
                        {selectedOption === "networkPrinter" && 
                            <div className="printer-cards-container">
                                {Object.values(printers).map(printer => 
                                    <div key={printer.uuid} className={`printer-card ${selectedNetworkPrinterUUID === printer.uuid ? "selected" : ""}`} onClick={() => setSelectedNetworkPrinterUUID(printer.uuid)}>
                                        <div className="name">{printer.name}</div>
                                        <div className="type">{printer.printerType}</div>
                                        <div className="path">{printer.commPath}</div>
                                    </div>
                                )}
                            </div>
                        }
                        {(selectedOption === "localPrinter" || selectedOption === "networkPrinter") && 
                            <div className="app-params">
                            <div className={`option ${localAppParams?.behaviour?.printQRCode ? "selected" : ""}`} onClick={() => checkOption("printQRCode", !localAppParams?.behaviour?.printQRCode)}>
                              <div className="inner-bg"></div>
                              <div className="param-name">{getT(`modal.personalSettings.option.printQRCode`)}</div>
                            </div>
                            <div className={`option ${localAppParams?.behaviour?.printCustomerBankcardSlip ? "selected" : ""}`} onClick={() => checkOption("printCustomerBankcardSlip", !localAppParams?.behaviour?.printCustomerBankcardSlip)}>
                              <div className="inner-bg" ></div>
                              <div className="param-name">{getT(`modal.personalSettings.option.printCustomerBankcardSlip`)}</div>
                            </div>
                          </div>
                        }
                        {selectedOption === "noPrinter" && getT("devices.noLocalPosPrinter")}
                    </div>
                    <div className="help-text-container">
                        {selectedOption === "noPrinter" && getT("localPrinter.noPrinter.helpText")}
                        {selectedOption === "localPrinter" && getT("localPrinter.localPrinter.helpText")}
                        {selectedOption === "networkPrinter" && getT("localPrinter.networkPrinter.helpText")}
                    </div>
                </div>
            </div>
        </div>
        <Actions onClickPrev={handleClickPrev} onClickNext={handleClickNext} />
        </>
    )
}
export default PosPrinter