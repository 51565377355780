import { useContext } from "react"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import Order from "./ExternalPaymentOrder"

function ExternalPaymentItems() {

    const { selectedExternalOrders } = useContext(SelectedOrderContext)


    return(
        <div className="external-payment-items">
            {Object.values(selectedExternalOrders || {}).map(order => <Order key={order.uuid} orderUUID={order.uuid} />)}
        </div>
    )
}
export default ExternalPaymentItems