import {  useContext, useRef, useState } from 'react'
import { useDevice, usePrinter, usePosPrinter, useClient, useOrder, useExternalPayment } from "okeoke.client"
import figlet from "figlet"
import standard from "figlet/importable-fonts/Standard.js"
import useMultilang from "intl/useMultilang"
import { toast } from "react-toastify"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import QRCode from "qrcode.react"
import { ModalContext } from "contexts/ModalContext"

export default function usePrint(orderUUID) {

    const { order, orderNumber, invoices }= useOrder(null, orderUUID)
    
    const { client } = useClient()
    const { printReceipt, printNonFiscal, createPrintSlip, generateQrSale, createExternalPaymentPrintSlip } = usePrinter(null)
    const { addPaymentInvoice } = useExternalPayment()
    const { posPrint, printers } = usePosPrinter()
    const { logError, deviceParams } = useDevice()
    const { getT } = useMultilang()

    const { loadModal } = useContext(ModalContext)
    const { localAppParams } = useContext(PersonalSettingsContext)
    
    const [printingReady, setPrintingReady] = useState(false)
    
    const customerBankcardSlip = useRef(null)

    const startPosPrintOrder = (params, payment) => {
      let { maxChars } = params
      let printSlip
        if(payment == null) {
          printSlip = createPrintSlip(order, {
            printQRCode: localAppParams?.behaviour?.printQRCode, 
            printNonFiscalDisclaimer: true, 
            ccPrintSlip: localAppParams?.behaviour?.printCustomerBankcardSlip && cutFestiPrintSlip(customerBankcardSlip.current != null ? { Rows: customerBankcardSlip.current } : null), 
            figletPrintSlip: createFigletFromOrderNumber(orderNumber, maxChars), 
            maxChars 
          })
        } else {
          printSlip = createExternalPaymentPrintSlip(payment, {
            printItems: true, 
            headerPrintSlip: null, 
            ccPrintSlip: localAppParams?.behaviour?.printCustomerBankcardSlip && cutFestiPrintSlip(customerBankcardSlip.current != null ? { Rows: customerBankcardSlip.current } : null), 
            figletPrintSlip: null,
            maxChars
          })
        }
        posPrint(printSlip).then(res => {
            setPrintingReady(true)
            if(res.success) {
                console.log("Pos print success", res.data)
            } else {
                console.log("Pos print error", res.data)
                toast.error(getT("modal.posPrinterError.slipNotPrinted"))
            }
        }).finally(() => customerBankcardSlip.current = null)
    }

    const startFiscalPrint = (params, payment) => {
        let { maxChars } = params
        if(invoices.length > 0) {
          console.log("Slip already printed")
        } else {
          if(payment == null) {
            printReceipt(order, createPrintSlip(order, { 
                printQRCode: localAppParams?.behaviour?.printQRCode,
                printItems: false, 
                headerPrintSlip: null, 
                ccPrintSlip: cutFestiPrintSlip(customerBankcardSlip.current != null ? { Rows: customerBankcardSlip.current } : null),
                figletPrintSlip: createFigletFromOrderNumber(orderNumber, maxChars),
                maxChars
            })).then(res => {
                console.log("Fiscal print (not external)", res)
                setPrintingReady(true)
                if(res.success) {
                    console.log("Fiscal print (not external) success", res)
                    addInvoice(0, res.data.amount, res.data.extID, res.data.vatInvoice, res.data, orderUUID)
                } else {
                    console.log("Fiscal printer error (not external) (fiscal receipt)", res)
                    logError(1, 100, "", res.data)
                    toast.error(getT("modal.fiscalPrinterError.fiscalReceipt"))
                }
            })
          } else {
            printReceipt(null, createExternalPaymentPrintSlip(payment, {
              printItems: false, 
              headerPrintSlip: null, 
              ccPrintSlip: cutFestiPrintSlip(customerBankcardSlip.current != null ? { Rows: customerBankcardSlip.current } : null),
              //figletPrintSlip: createFigletFromOrderNumber(orderNumber, maxChars),
              maxChars
            }), payment).then(res => {
              console.log("Fiscal print (external)", res)
              setPrintingReady(true)
              if(res.success) {
                  console.log("Fiscal print (external) success", res)
                  addPaymentInvoice(payment.uuid, 0, res.data.amount, res.data.extID, res.data.vatInvoice, res.data)
              } else {
                  console.log("Fiscal printer error (external) (fiscal receipt)", res)
                  logError(1, 100, "", res.data)
                  toast.error(getT("modal.fiscalPrinterError.fiscalReceipt"))
              }
          })
          }
        }
    }
    
    const startBankCardSlipPrint = (printSlip) => {
    
        let slip = cutFestiPrintSlip({ Rows: printSlip })

        let printerType = deviceParams?.printer?.type
        let usePosPrinter = deviceParams?.posPrinter?.selected
        let useFiscalPrinter = deviceParams?.printer?.selected
        if(useFiscalPrinter) {
          if(printerType === "Fiscat") {
            printNonFiscal(slip).then(res => {
              if(res.success) {
                console.log("Bank card slip print success", res)
              } else {
                console.log("Fiscal printer error (bank card slip)", res)
                logError(1, 100, "", res.data)
                toast.error(getT("modal.fiscalPrinterError.nonFiscalReceipt.bankCardSlip"))
              }
            })
          }
        } else {
          if(usePosPrinter) {
            posPrint(slip).then(res => {
              if(res.data.success) {
                console.log("Pos print success", res.data)
              } else {
                console.log("Bank card pos print error", res.data)
                toast.error(getT("modal.posPrinterError.bankCardSlipNotPrinted"))
              }
            })
          } else {
            toast.error(getT("modal.posPrinterError.bankCardSlipNotPrinted"))
          }
        }
    }

    // IDEIGLENES FUNKCIO!
    const cutFestiPrintSlip = (recentpay) => {
        if(recentpay == null) return null
        let reached = false;
        let newSlip = { printSlip: { Rows: [] } };
        if (recentpay?.Rows) {
            newSlip.printSlip.Rows = [
            ...newSlip.printSlip.Rows,
            { Bold: false, Msg: "----------------------------------------" },
            ];
            for (let row of recentpay.Rows) {
            if (reached) {
                newSlip.printSlip.Rows = [...newSlip.printSlip.Rows, row];
            }
            if (row.Msg.includes("########")) {
                reached = true;
            }
            }
        }
        if(!reached) {
            return recentpay
        } else {
            return newSlip.printSlip;
        }
    };
    
    const createFiglet = (text) => {
        figlet.parseFont("Standard", standard);
        return figlet.textSync(text);
    };
      
    function strCenter(source, maxChars) {
        let spaces = maxChars - source.length;
        let padLeft = spaces / 2 + source.length;
        return source.padStart(padLeft, ' ')
    }
    
    const createFigletFromOrderNumber = (orderNumber, maxChars) => {
        let orderNumberArray = (orderNumber || '').split("/")
        let printSlip = { Rows: [] };
        let textarray = createFiglet(orderNumberArray.pop()).split("\n");
        for (let row of textarray) {
          printSlip.Rows = [
            ...printSlip.Rows,
            { Bold: false, Msg: strCenter(row, maxChars) },
          ];
        }
        return printSlip
    }

    const addInvoice = (invoiceAgentID, amount, extID, vatInvoice, data, orderUUID) => {
        client.orders.loadOrder(orderUUID).then(res => {
          if(res != null) {
            order.invoices.addInvoice(invoiceAgentID, amount, extID, vatInvoice, data).then(res => {
              if(res.success) {
                console.log("Invoice added", res)
              } else {
                console.log("Add invoice failed", res)
              }
            })
          } else {
            console.log("Load order error!")
          }
        })
    }

    const setCustomerBankcardSlip = value => customerBankcardSlip.current = value

    const handlePrinting = (payment) => {
        let printerType = deviceParams?.printer?.type
        let usePosPrinter = deviceParams?.posPrinter?.selected
        let useFiscalPrinter = deviceParams?.printer?.selected
        let maxCharsPosPrinter = printers?.[deviceParams?.posPrinter?.uuid]?.maxChars || 48
        let maxCharsFiscalPrinter = deviceParams?.printer?.maxChars || 41
        console.log("Handle printing: usePosPrinter, useFiscalPrinter", usePosPrinter, useFiscalPrinter)
        if(useFiscalPrinter) {
            if(printerType == null || printerType === "") {
                setPrintingReady(true)
            } else {
                if(printerType === "Fiscat") {
                  startFiscalPrint({ maxChars: maxCharsFiscalPrinter }, payment)
                }
                if(printerType === "FiscatIPalm") {
                    /* Show Fiscat IPalm QR code, then ok button triggers the start of next order */
                    let codeQR = payment != null ? generateQrSale(null, payment) : generateQrSale(order)
                    loadModal(
                    <div className="open-in-modal-root">
                        <h1>{getT("modal.fiscatIPalm.title")}</h1>
                        <QRCode
                        value={codeQR}
                        includeMargin={true}
                        renderAs="canvas"
                        size={300}
                        level="L"
                        />
                        <div className="button-cotainer">
                        <div className="btn" onClick={() => setPrintingReady(true)}>
                            {getT("modal.buttonResponse.ok")}
                        </div>
                        </div>
                    </div>
                    )
                }
            } 
        }
        if(usePosPrinter) {
          startPosPrintOrder({ maxChars: maxCharsPosPrinter }, payment)
        }
        if((!usePosPrinter) && (!useFiscalPrinter)) setPrintingReady(true)
    }

    const handleExternalOrderPrint = (data) => {
      console.log("handleExternalOrderPrint", data.payment)
      handlePrinting(data.payment)
    }

    return {
      startBankCardSlipPrint,
      cutFestiPrintSlip,
      generateQrSale,
      setCustomerBankcardSlip,
      handlePrinting,
      handleExternalOrderPrint,
      printingReady
    }
} 