import OrderCard from 'views/OrderManager/OpenOrders/OrderCard'
import useMultilang from "intl/useMultilang"


function OrderCardContainer(props) {

    const { 
        orderList, 
        readOnly, 
        pingedOrderUUID, 
        setPingedOrderUUID, 
        addToExternalOrders,
        selectedExternalOrders,
        externalOrderPayment,
        removeExternalOrder
    } = props

    const { getT } = useMultilang()

    return(
        <div id="order-card-container" className="order-view-card-container hidden appear">
            {orderList.length < 1 && getT("openorders.noOrders")}
            {orderList.map(order => 
                <OrderCard
                    key={order.uuid}
                    orderUUID={order.uuid}
                    pingedOrderUUID={pingedOrderUUID}
                    setPingedOrderUUID={setPingedOrderUUID}
                    readOnly={readOnly}
                    addToExternalOrders={addToExternalOrders}
                    selected={selectedExternalOrders?.[order.uuid] != null}
                    externalOrderPayment={externalOrderPayment}
                    removeExternalOrder={removeExternalOrder}
                />
            )}
        </div>
    )
}
export default OrderCardContainer