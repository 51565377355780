import { useState } from "react"
import InputMask from "react-input-mask"

function RegStep4(props) {

    const { activeScreen, setActiveScreen, handleChangeUserData, userData } = props

    const [localUserData, setLocalUserData] = useState(userData)
    const [error, setError] = useState({})

    const handleBlur = (e) => {
        let value = e.target.value

        if(e.target.name === "telNumber") {
            value = formatNumber(e.target.value)
            setError({...error, telNumber: value.length !== 12})
        }

        if(e.target.name === "zip") {
            value = formatNumber(e.target.value)
            setError({...error, zip: value.length !== 4})
        }

        if(e.target.name === "name") {
            handleChangeUserData("location", {...userData, [e.target.name]: value})
        } else {
            handleChangeUserData("location", {...userData, address: {...userData.address, [e.target.name]: value}})
        }

    }

    const handleChange = (e) => {

        let value = e.target.value

        if(e.target.name === "telNumber") {
            value = formatNumber(e.target.value)
            setError({...error, telNumber: value.length < 11})
        }

        if(e.target.name === "zip") {
            value = formatNumber(e.target.value)
            setError({...error, zip: value.length !== 4})
        }

        setLocalUserData({...localUserData, [e.target.name]: e.target.value})
    }

    const replaceAll = (str, mapObj) => {
        if(str == null) return null
        let re = new RegExp(Object.keys(mapObj).join("|"),"gi")
    
        return str.replace(re, function(matched){
            return mapObj[matched.toLowerCase()]
        })
    }

    const formatNumber = input => replaceAll(input, {'_': '', ' ': ''})

    const handleNext = () => {
        if(!error.telNumber && !error.zip && userData.name !== "" && userData.address.telNumber !== "" && userData.address.city !== "" && userData.address.street !== "" && userData.address.streetNumber !== "" && userData.address.zip !== "") {
            setActiveScreen("reg-step5")
        }
    }

    if(activeScreen !== "reg-step4") return null

    return(
        <div className="device-login-reg-step step4">
            <div className="welcome-message">Regisztráció</div>
            <div className="hidden appear welcome-message2">Most kérjük add meg az üzleted adatait!</div>
            <input className="hidden appear" name="name" value={localUserData.name || ""} placeholder="Üzlet neve" onBlur={handleBlur} onChange={handleChange}/>
            <InputMask
                mask="+36 99 999 9999"
                name="telNumber"
                type="text"
                className={`textField-input ${((localUserData?.telNumber || "") !== "" && error.telNumber) ? "error" : ""}`}
                value={localUserData?.telNumber || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Telefonszám"
            />   
            <InputMask
                mask="9999"
                name="zip"
                type="text"
                className={`textField-input ${((localUserData?.zip || "") !== "" && error.zip) ? "error" : ""}`}
                value={localUserData?.zip || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Irányítószám"
            />            
            <input className="hidden appear" name="city" value={localUserData.city || ""} placeholder="Város" onBlur={handleBlur} onChange={handleChange}/>           
            <input className="hidden appear" name="street" value={localUserData.street || ""} placeholder="Utca" onBlur={handleBlur} onChange={handleChange}/>           
            <input className="hidden appear" name="streetNumber" value={localUserData.streetNumber || ""} placeholder="Házszám" onBlur={handleBlur} onChange={handleChange}/>           
            <div className="hidden appear  welcome-message3">Az itt megadott adatok mind fontosak lesznek a fiók létrehozásában.</div>
            <div className="hidden appear  btn-container">
                <div className={`btn highlighted ${(!error.telNumber && !error.zip && localUserData.name !== "" && localUserData.telNumber !== "" && localUserData.city !== "" && localUserData.street !== "" && localUserData.streetNumber !== "" && localUserData.zip !== "") ? "" : "disabled"}`} onClick={handleNext}>TOVÁBB</div>
                <div className="btn" onClick={() => setActiveScreen("reg-step3")}>vissza</div>
            </div>
        </div>
    )
}
export default RegStep4