import { useContext } from "react"
import { MainContext } from "contexts/MainContext"
import useMultilang from "intl/useMultilang"
import { isMobileOnly } from "react-device-detect"
import LoadSvg from "components/LoadSvg-v2"

function Header(props) {

  const { activeView, setView } = props
  const { getT } = useMultilang()
  const { featureList } = useContext(MainContext)

  return (
    <div className="open-orders-header-root">
      {featureList?.tableManager === 1 && <div className={`btn ${activeView === "tableManager" ? "btn-highlighted" : ""}`} onClick={() => setView("tableManager")} >
        {!isMobileOnly && getT("orderManager.header.tableManager")}
        {isMobileOnly && <LoadSvg name="table" />}
      </div>}
      <div className={`btn ${activeView === "openOrders" ? "btn-highlighted" : ""}`} onClick={() => setView("openOrders")}>
        {!isMobileOnly && getT("orderManager.header.openOrders")}
        {isMobileOnly && <LoadSvg name="PurchaseTagIcon" />}
      </div>
      {/* <div className={`btn ${activeView === "needToPay" ? "btn-highlighted" : ""}`} onClick={() => setView("needToPay")}>
        {!isMobileOnly && getT("orderManager.header.needToPay")}
        {isMobileOnly && <LoadSvg name="dollar" />}
      </div> */}
    </div>
  )
}
export default Header