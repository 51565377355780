import { lazy, Suspense } from "react"
import LoadingScreen from "components/LoadingScreen"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorFallback } from "utils/ErrorHandler"
import { Route, Routes } from "react-router-dom"
import { PreviousOrderProvider } from "contexts/PreviousOrderContext"

const OrderManager = lazy(() => import('../../views/OrderManager'))
const Sell = lazy(() => import('../../views/Sell'))
const Editor = lazy(() => import('../../views/Editor'))
const EditItem = lazy(() => import('../../views/Editor/EditItem'))
const Settings = lazy(() => import('../../views/Admin'))
const Statistics = lazy(() => import('../../views/Admin/Statistics'))
const Orders = lazy(() => import('../../views/Admin/Orders'))
const DeviceSettings = lazy(() => import('../../views/Admin/Device/DeviceSettings'))
const FiscalPrinterSettings = lazy(() => import('../../views/Admin/Printers/FiscalPrinterSettings'))
const CommonPrinterSettings = lazy(() => import('../../views/Admin/Printers/CommonPrinterSettings'))
const DeviceCustomization = lazy(() => import('../../views/Admin/Device/DeviceCustomization'))
const ItemSales = lazy(() => import('../../views/Admin/Reports/ItemSales'))
const VatSum = lazy(() => import('../../views/Admin/Reports/VatSum'))
const Transactions = lazy(() => import('../../views/Admin/Reports/Transactions'))
const Discounts = lazy(() => import('../../views/Admin/Reports/Discounts'))
const Summary = lazy(() => import('../../views/Admin/Reports/Summary'))
const PaymentType = lazy(() => import('../../views/Admin/Reports/PaymentType'))
const Users = lazy(() => import('../../views/Admin/Reports/Users'))
const Devices = lazy(() => import('../../views/Admin/Reports/Devices'))


function Router() {

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path={"/"} exact element={<ErrorBoundary FallbackComponent={ErrorFallback}><Sell /></ErrorBoundary>}/>
        <Route path={"editor"} exact element={<ErrorBoundary FallbackComponent={ErrorFallback}><Editor/></ErrorBoundary>}/>
        <Route path={"edit-item/:itemUUID"} exact element={<ErrorBoundary FallbackComponent={ErrorFallback}><EditItem/></ErrorBoundary>}/>
        <Route path={"open-orders"} exact element={<ErrorBoundary FallbackComponent={ErrorFallback}><OrderManager /></ErrorBoundary>}/>
        <Route path={"open-orders/:id"} exact element={<ErrorBoundary FallbackComponent={ErrorFallback}><OrderManager /></ErrorBoundary>}/>
        <Route path={"admin"} >
          <Route path={"main"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Settings /></ErrorBoundary>} />
          <Route path={"orders"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><PreviousOrderProvider><Orders /></PreviousOrderProvider></ErrorBoundary>} />
          <Route path={"statistics"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Statistics /></ErrorBoundary>}/>
          <Route path={"device"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><DeviceSettings /></ErrorBoundary>}/>
          <Route path={"fiscal-printer"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><FiscalPrinterSettings /></ErrorBoundary>}/>
          <Route path={"common-printer"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><CommonPrinterSettings /></ErrorBoundary>}/>
          <Route path={"device-customization"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><DeviceCustomization /></ErrorBoundary>}/>
          <Route path={"item-sales"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><ItemSales /></ErrorBoundary>}/>
          <Route path={"vat-sum"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><VatSum /></ErrorBoundary>}/>
          <Route path={"transactions"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Transactions /></ErrorBoundary>}/>
          <Route path={"discounts"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Discounts /></ErrorBoundary>}/>
          <Route path={"summary"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Summary /></ErrorBoundary>}/>
          <Route path={"payment-type"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><PaymentType /></ErrorBoundary>}/>
          <Route path={"users"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Users /></ErrorBoundary>}/>
          <Route path={"devices"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Devices /></ErrorBoundary>}/>
        </Route>
      </Routes>
    </Suspense>
  )
}
export default Router
