import { useContext, useEffect, useState } from "react"
import { useOrder } from "okeoke.client"
import Item from "../Item"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import useMultilang from "intl/useMultilang"
import LoadSvg from "components/LoadSvg-v2"

function ExternalPaymentOrder(props) {

    const { orderUUID } = props

    const { incItemPayment, setItemPayment, decItemPayment, itemPayments } = useContext(SelectedOrderContext)

    const { orderItemsAssembled, orderNumber, orderIdentifier } = useOrder(null, orderUUID)
    const { getT } = useMultilang()

    const [items, setItems] = useState([])

    useEffect(() => {
        let itemArray = Object.values(orderItemsAssembled || {}).filter(assembledItem => (assembledItem?.count > 0) && (!((assembledItem?.payment?.count || 0) === (assembledItem?.count || 0))))
        setItems(itemArray)
    }, [orderItemsAssembled])

    const handleAddItem = (item) => {
        incItemPayment(orderUUID, item.orderItemUUID)
    }

    const handleAddAllItem = (item) => {
        setItemPayment(orderUUID, item.orderItemUUID, item.count)
    }

    const handleDecItem = (item) => {
        decItemPayment(orderUUID, item.orderItemUUID)
    }

    if(items.length === 0) return null

    return(
        <div className="external-payment-order">
            <div className="order-header">
                <div className="order-identifier">{orderIdentifier === "" ? getT("tableView.noIdentifier") : orderIdentifier}</div>
                <div className="order-number">{orderNumber}</div>
            </div>
            <div className="order-items">
                {items
                .map(assembledItem => 
                    <div key={assembledItem.orderItemUUID} className="item-outer-container">
                        <Item
                            assembledItem={assembledItem}
                            showDetails={localStorage['showItemDetailsInBasket']}
                            onClick={() => handleAddItem(assembledItem)}
                        />
                        <div className="actions">
                            {(assembledItem?.count || 0) - (itemPayments?.[assembledItem?.orderItemUUID]?.cnt || 0) !== 0 && <div className="action-btn" onClick={() => handleAddItem(assembledItem)}><LoadSvg name="chevron" /></div>}
                            {(assembledItem?.count || 0) - (itemPayments?.[assembledItem?.orderItemUUID]?.cnt || 0) === 0 && <div className="action-btn rotate180" onClick={() => handleDecItem(assembledItem)}><LoadSvg name="chevron" /></div>}
                            <div className={`action-btn ${(assembledItem?.count || 0) - (itemPayments?.[assembledItem?.orderItemUUID]?.cnt || 0) === 0 ? "passive" : ""}`} onClick={() => handleAddAllItem(assembledItem)}><LoadSvg name="chevronD" /></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default ExternalPaymentOrder