import { useContext } from "react"
import { PaymentContext } from "contexts/PaymentContext"
import NumberFormat from 'react-number-format'
import useMultilang from "intl/useMultilang"
import LoadSvg from "components/LoadSvg-v2"

function Numpad(props) {

  const { visible } = props

  const { currentAmount, handleSetCurrent, bankCardPaymentInProgress, addPaymentInProgress } = useContext(PaymentContext)
  const { getT } = useMultilang()

  const changeAmount = (e) => {
    if (e === "." && (""+currentAmount).includes(".")) return null
    if (""+currentAmount === "0" && e === "0") return null
    if (""+currentAmount === "0" && e === "00") return null
    if (""+currentAmount === "0" && e === ".") {
      handleSetCurrent("0.")
    } else {
      if (""+currentAmount === "0") {
        handleSetCurrent("" + e);
      } else {
        if(currentAmount < 100000 && currentAmount.length < 10) handleSetCurrent(currentAmount + "" + e)
      }
    }
  }

  if(!visible) return null

  return (
    <>
    {bankCardPaymentInProgress && <div className="hidden quickAppear printing-alert">{getT("alert.bankCardTransactionInProgress")}</div>}
    {addPaymentInProgress && <div className="hidden quickAppear printing-alert">{getT("alert.addPaymentInProgress")}</div>}
    {/*<div className="hidden quickAppear printing-alert">{getT("alert.loading")}</div>*/}
    <div className="hidden quickAppear payment-numpad-root">
      <div className="current-number">
        <NumberFormat value={parseFloat(currentAmount)} decimalSeparator="," suffix=" Ft" thousandSeparator="." displayType="text"/>
        <div className="clear" onClick={() => handleSetCurrent("0")}><LoadSvg name="BinIcon" /></div>
      </div>
      <div className="payment-numpad-body">
        <div className="numpad-btn" onClick={() => changeAmount("7")}>
          <div className="number">7</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("8")}>
          <div className="number">8</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("9")}>
          <div className="number">9</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount(".")}>
          <div className="number">,</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("4")}>
          <div className="number">4</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("5")}>
          <div className="number">5</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("6")}>
          <div className="number">6</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("0")}>
          <div className="number">0</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("1")}>
          <div className="number">1</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("2")}>
          <div className="number">2</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("3")}>
          <div className="number">3</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("00")}>
          <div className="number">00</div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Numpad
