import { useContext, useEffect, useState } from "react"
import { PaymentContext } from "contexts/PaymentContext"
import useMultilang from "intl/useMultilang"
import { ModalContext } from "contexts/ModalContext"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import TipModal from "./TipModal"
import { useMain, useLocation } from 'okeoke.client'
import LoadSvg from "components/LoadSvg-v2"
import { useVip } from "okeoke.client"

function PaymentButtons() {

  const {
    selectedPayment,
    handlePaymentTypeChange,
    proceedPayment,
    currentAmount,
    devicePaymentAgents,
    paymentsNeeded,
    tipAmount,
    setTipAmount,
    handleSetCurrent,
    orderVipID,
    externalPayment,
    externalTipAmount
  } = useContext(PaymentContext)
  const { language, getT } = useMultilang()
  const { addModal, popModal } = useContext(ModalContext)
  const [update, setUpdate] = useState(null)
  const { location } = useLocation()
  const { money } = useVip(orderVipID)

  useEffect(() => {
    messageSecondary({ action: "updateTipAmount", data: tipAmount })
    // eslint-disable-next-line
  }, [tipAmount, update])
  
  const getData = (message) => {
    if(message?.action === "update") setUpdate(message?.data)
    if(message?.action === "setTipAmount") {
      if(location?.localConfig?.tippType !== 0) setTipAmount(message?.data)
    }
  }
  const { messageSecondary } = useMain(getData)

  const handlePaymentSelect = (paymentAgent) => {
    //console.log(paymentAgent, selectedPayment)
    if(selectedPayment != null && selectedPayment?.paymentAgentID === paymentAgent.paymentAgentID) {
      if (+currentAmount === 0) {
        if(paymentAgent.agentType === "vip") {
          handleSetCurrent(money)
        } else {
          handleSetCurrent(paymentsNeeded)
        }
      } else {
        if(!externalPayment) proceedPayment(currentAmount, paymentAgent)
      }
    } else {
      if(currentAmount > 0 ) {
        handlePaymentTypeChange(paymentAgent)
      } else {
        handlePaymentTypeChange(paymentAgent)
        if(paymentAgent.agentType === "vip") {
          handleSetCurrent(money > paymentsNeeded ? paymentsNeeded : money)
        } else {
          handleSetCurrent(paymentsNeeded)
        }
      }
    }
  }

  const handleSubmitTip = (amount) => {
    if(location?.localConfig?.tippType === 0) return null
    setTipAmount(amount)
    popModal()
  }

  const openTipModal = () => {
    if(location?.localConfig?.tippType === 0) return null
    addModal(
      <Modal key={uuidv4()} className="tip-modal" onClickLayout={popModal}>
        <TipModal 
          paymentsNeeded={paymentsNeeded} 
          tipAmount={externalPayment ? externalTipAmount : tipAmount}
          popModal={popModal}
          onSubmit={handleSubmitTip}
        />
      </Modal>
    )
  }

  return (
    <div className="payment-button-container-root ">
      <div className="top-row-payment-button">
        {devicePaymentAgents
        .filter(paymentAgent => paymentAgent.agentType === "cash" || paymentAgent.agentType === "bankCard" || (paymentAgent.agentType === "vip" && money > 0))        
        .map((paymentAgent, index) => 
          <div
            key={index}
            className={`payment-button ${selectedPayment?.paymentAgentID === paymentAgent.paymentAgentID ? "selected" : ""} ${paymentAgent.agentType}`}
            onClick={() => handlePaymentSelect(paymentAgent)}
          >
            {paymentAgent.agentType === "cash" && <LoadSvg name="coins" />}
            {paymentAgent.agentType === "bankCard" && <LoadSvg name="creditCard" />}
            {selectedPayment?.paymentAgentID !== paymentAgent.paymentAgentID ? paymentAgent?.name?.[language] : `${getT("payment.pay")}: ${paymentAgent?.name?.[language]}`}
            {money > 0 && paymentAgent.agentType === "vip" && ` (${money} Ft)`}
          </div>
        )}
      </div>
      {location?.localConfig?.tippType !== 0 && <div className="bottom-row">
        <div className="payment-button tipp-btn" onClick={openTipModal}>
          <LoadSvg name="tipp"/>
          {getT("payment.tip")}
        </div>
      </div>}
      <div className="top-row-payment-button">
        {devicePaymentAgents
        .filter(paymentAgent => paymentAgent.agentType !== "cash" && paymentAgent.agentType !== "bankCard" && paymentAgent.agentType !== "vip")
        .map((paymentAgent, index) =>   
          <div
              key={index}
              className={`payment-button ${selectedPayment?.paymentAgentID === paymentAgent.paymentAgentID ? "selected" : ""}`}
              onClick={() => handlePaymentSelect(paymentAgent)}
            >
              {paymentAgent.agentType === "cash" && <LoadSvg name="coins" />}
              {paymentAgent.agentType === "bankCard" && <LoadSvg name="creditCard" />}
              {selectedPayment?.paymentAgentID !== paymentAgent.paymentAgentID ? paymentAgent?.name?.[language] : `${getT("payment.pay")}: ${paymentAgent?.name?.[language]}`}
            </div>
        )}
      </div>
    </div>
  )
}

export default PaymentButtons
