import { useContext, useEffect, useState } from "react"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import { PaymentContext } from "contexts/PaymentContext"
import LoadSvg from "components/LoadSvg-v2"

function ItemPayments() {

    const { itemPayments, decItemPayment, setItemPayment } = useContext(SelectedOrderContext)
    const { proceedPayment, selectedPayment, orderObjects } = useContext(PaymentContext)
    const { getT, language } = useMultilang()

    const [totalPaymentsNeeded, setTotalPaymentsNeeded] = useState(0)

    useEffect(() => {
        setTotalPaymentsNeeded((Object.values(itemPayments || {})).reduce((sum, a) => sum + +a.amt, 0))
    }, [itemPayments])


    const handleDecreaseCount = (orderID, itemID) => {
        decItemPayment(orderID, itemID)
    }

    const removeAll = (orderID, itemID) => {
        setItemPayment(orderID, itemID, 0)
    }

    const handleSubmit = () => {
        if(totalPaymentsNeeded > 0 && selectedPayment != null) {
            proceedPayment(totalPaymentsNeeded, selectedPayment)
        }
    }

    return(
        <div className="item-payments">
            <div className="items-container">
                {Object.values(itemPayments || {}).map(item => 
                    <div key={item.iid} className="item-container">
                        <div className="count">{item.cnt}x</div>
                        <div className="item-name">{orderObjects?.[item.oid]?.assembledOrderItems?.[item.iid]?.name?.[language]}</div>
                        <div className="amount">
                            <NumberFormat
                                value={item.amt}
                                decimalSeparator=","
                                suffix=" Ft"
                                thousandSeparator="."
                                displayType="text"
                            />
                        </div>
                        <div className="actions">
                            <div className="action-btn rotate180" onClick={() => handleDecreaseCount(item.oid, item.iid)}><LoadSvg name="chevron"/></div>
                            <div className="action-btn rotate180" onClick={() => removeAll(item.oid, item.iid)}><LoadSvg name="chevronD"/></div>
                        </div>
                    </div>)
                }
            </div>
            <div className={`pay-btn-container ${(selectedPayment != null && totalPaymentsNeeded > 0) ? "" : "disabled"}`} onClick={handleSubmit}>
                <div className="label">{getT("externalPayment.paymentsNeeded")}</div>
                <div className="value">
                    <NumberFormat
                        value={totalPaymentsNeeded}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                    />
                </div>
            </div>
        </div>
    )
}
export default ItemPayments