import { useContext } from "react"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import Modal from "components/Modal/Modal"
import Payment from "views/Payment"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import { MainContainerContext } from "contexts/MainContainerProvider"

function ExternalPaymentPanel() {

    const { selectedExternalOrders, removeExternalOrder } = useContext(SelectedOrderContext)
    const { orderObjects } = useContext(MainContainerContext)
    const { addModal } = useContext(ModalContext)

    const { getT } = useMultilang()

    const handleExternalPay = () => {
        addModal(
          <Modal key={uuidv4()} className="payment-modal">
            <Payment external={true} orderObjects={orderObjects} />
          </Modal>
        )
    }

    if(Object.values(selectedExternalOrders || {}).length === 0) return null

    return(
        <div className="external-payment-panel-content">
            <div className="title">{getT("externalPaymentPanel.title")}</div>
            <div className="orders-container">
                {Object.values(selectedExternalOrders).map(order => 
                    <div key={order.uuid} className="order-btn">
                        <div className="drop-order-container">
                            <LoadSvg name={"BinIcon"} onClick={() => removeExternalOrder(order.uuid)} className={"icon-btn"}/>
                        </div>
                        <div className="order-summary-title">
                            <div className="order-identifier">
                                {order.orderIdentifier === "" ? getT("sell.basket.order") : order.orderIdentifier}
                            </div>
                            <div className="order-number">
                                {order.orderNumber}
                            </div>
                        </div>
                        <div className="order-payments-needed">
                            <NumberFormat
                                value={+order.paymentsNeeded}
                                decimalSeparator=","
                                suffix=" Ft"
                                thousandSeparator="."
                                displayType="text"
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="footer">
                <div className="btn btn-highlighted" onClick={handleExternalPay}>
                    <div className="text">{getT("externalPaymentPanel.pay")}:</div>
                    <NumberFormat
                        value={+Object.values(selectedExternalOrders).reduce((sum, a) => sum + +(a?.paymentsNeeded || 0), 0)}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                    />
                </div>
            </div>
        </div>
    )
}
export default ExternalPaymentPanel