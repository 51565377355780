import TextField from "components/TextField"
import InputFieldSelect from "components/InputFieldSelect"
import InputSwitch from "components/InputSwitch"
import useMultilang from "intl/useMultilang"
import { useOkeFrame } from "okeoke.client"
import { useEffect, useState } from "react"
import InputFieldMultiSelect from "components/InputFieldMultiSelect"
import usePrepCountersApi from "apis/catalog/usePrepCountersApi"

function PosPrinterSettings(props) {
    
    const { localParams, changeFieldData, isPrivate, setIsPrivate } = props
    const { frameType, getPrinters, getSerialPorts } = useOkeFrame()
    const { getPrepCounters } = usePrepCountersApi()
    const [prepCounters, setPrepCounters] = useState(null)
    const [availablePorts, setAvailablePorts] = useState(null)
    const [availablePrinters, setAvailablePrinters] = useState(null)
    const [selectedPort, setSelectedPort] = useState(null)
    const [selectedPrinter, setSelectedPrinter] = useState(null)
    const { getT } = useMultilang()

    const commTypeOptions = [{ title: "Net", value: "net"}, frameType === "okeElectron" ? { title: "Com", value: "com"} : null, frameType !== "NONE" ? { title: "Printer", value: "printer"} : null] 

    useEffect(() => {
        if(availablePorts == null && localParams?.commType === "com") {
            let portArray = []
            getSerialPorts().then(res => {
                if(res.success) {
                    for(let port of res.data) {
                        portArray.push({ title: port.path, value: port.path })
                    }
                }
            })
            setAvailablePorts(portArray)
            //console.log(ports)
        }
        if(selectedPrinter == null && localParams?.commType === "printer") {
            let printerArray = []
            getPrinters().then(res => {
                if(res.success) {
                    for(let printer of res.data) {
                        console.log(printer)
                        printerArray.push({ title: printer.name, value: printer.name })
                    }
                }
            })
            setAvailablePrinters(printerArray)
        }
        if(prepCounters == null && localParams?.printOrder) {
            getPrepCounters().then(res => {
                if(res.success) {
                    setPrepCounters(res.data.filter(counter => counter.enabled))
                }
            })
        }
        // eslint-disable-next-line
    }, [localParams])

    const selectComPort = (e) => {
        changeFieldData({target: {name: "commPath", value: e.target.value}})
        setSelectedPort(e.target.value)
    }

    const selectPrinter = (e) => {
        setSelectedPrinter(e.target.value)
        changeFieldData({target: {name: "commPath", value: e.target.value}})
    }

    const handlePrintOrder = (e) => {
        changeFieldData({target: {name: e.target.name, value: e.target.checked}})
    }

    const inputFieldMultiSelectHeaderFormat = (e) => {
        if (e.length === 0) return getT("device.printer.prepCounter.noneSelected")
        return e.length + " " + getT("device.printer.prepCounter.selected")
    }

    const inputFieldMultiSelectOnChange = (e) => {
        changeFieldData({target: {name: "prepCounters", value: e}})
    }
    
    return(
        <div className="settings-fields">
            <TextField 
                label={getT("devices.posPrinterName")}
                name="name"
                onChange={changeFieldData}
                value={localParams?.name || ""}
                type="text"
            />
            <InputFieldSelect 
                label={getT("devices.posPrinterType")}
                name="printerType"
                onChange={changeFieldData}
                value={localParams?.printerType}
                displayedDefaultName={getT("devices.selectPosPrinterType")}
                options={[{ title: "Epson", value: "EPSON"}, { title: "Star", value: "STAR"}]}
            />
            <TextField 
                label={getT("devices.posPrinterMaxchars")}
                name="maxChars"
                onChange={changeFieldData}
                value={localParams?.maxChars || ""}
                type="text"
            />
            <InputFieldSelect 
                label={getT("devices.posPrinterCommType")}
                name="commType"
                onChange={changeFieldData}
                value={localParams?.commType}
                displayedDefaultName={getT("devices.selectcommType")}
                options={commTypeOptions}
            />  
            <TextField 
                label={getT("devices.posPrintercommPath")}
                name="commPath"
                onChange={changeFieldData}
                value={localParams?.commPath}
                type="text"
            />     
            {localParams?.commType === "com" && 
                <InputFieldSelect 
                    label={getT("devices.availableComPort")}
                    name="commType"
                    onChange={selectComPort}
                    value={selectedPort}
                    displayedDefaultName={getT("devices.selectAvailableComPort")}
                    options={availablePorts}
                />  
            }
            {localParams?.commType === "printer" && 
                <InputFieldSelect 
                    label={getT("devices.availablePrinter")}
                    name="commType"
                    className="available-printers"
                    onChange={selectPrinter}
                    value={selectedPrinter}
                    displayedDefaultName={getT("devices.selectAvailablePrinter")}
                    options={availablePrinters}
                />  
            }
            {localParams?.commType === "net" &&
                <TextField 
                    label={getT("devices.posPrintercommPort")}
                    name="commPort"
                    onChange={changeFieldData}
                    value={localParams?.commPort}
                    type="text"
                />
            }
            <div className="printer-private-container">
                <div className={`private ${!isPrivate ? "selected" : ""}`} onClick={() => setIsPrivate(false)}>
                    <div className="inner-bg"></div>
                    {getT("localPrinter.networkPrinter.shared")}
                </div>
                <div className={`private ${isPrivate ? "selected" : ""}`} onClick={() => setIsPrivate(true)}>
                    <div className="inner-bg"></div>
                    {getT("localPrinter.networkPrinter.private")}
                </div>
            </div>
            <InputSwitch
                label={getT("devices.printer.printOrder")}
                name="printOrder"
                onChange={handlePrintOrder}
                checked={localParams?.printOrder || false}
                
            />
            {localParams?.printOrder && <InputFieldMultiSelect 
                title={getT("devices.printer.prepcounters")}
                className="multi-select-pos-design"
                headerFormat={inputFieldMultiSelectHeaderFormat}
                onChange={inputFieldMultiSelectOnChange}
                selectedElements={(localParams?.prepCounters || []).map(counter => counter)}
                options={Object.values(prepCounters || {}).map(counter => { return { title: counter.name, value: counter.id } })}
            />}
        </div>
    )
}
export default PosPrinterSettings