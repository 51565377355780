import { createContext, useEffect, useState, useContext, useRef} from "react"
import { useDevice, usePos, usePosPrinter } from "okeoke.client"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { useMain } from "okeoke.client"
import useMultilang from "intl/useMultilang"
import { toast } from "react-toastify"
import AddBankCardPaymentModal from "components/AddBankCardPaymentModal"
import usePrint from "hooks/usePrint"

const PaymentContext = createContext()

const PaymentProvider = (props) => {

  const { submitOrderAfterPayment, external, orderObjects } = props

  const { 
    selectedOrderUUID,
    paymentsNeeded,
    paymentsTotal,
    discountPrice,
    totalPrice,
    order,
    payments,
    submitOrder,
    addPayment,
    orderNumber,
    serviceFee,
    invoices,
    tipAmount,
    setTipAmount,
    startOrder,
    orderState,
    setFirstAvailableBasket,
    orderVipID,
    payVipMoney,
    selectedOrdersSum,
    submitPayment,
    externalOrderPayment
  } = useContext(SelectedOrderContext)

  const { 
    startBankCardSlipPrint, 
    handlePrinting, 
    setCustomerBankcardSlip,
    printingReady,
    handleExternalOrderPrint 
  } = usePrint(selectedOrderUUID)

  const { loadModal, popModal, closeAllModal } = useContext(ModalContext)
  const { getT } = useMultilang()

  const { devicePaymentAgents, logError, deviceParams } = useDevice()
  const { printers } = usePosPrinter()
  const { startPayment, updatePayment } = usePos()

  const [selectedPayment, setSelectedPayment] = useState(null)
  const [currentAmount, setCurrentAmount] = useState("0")
  const [bankCardPaymentInProgress, setBankCardPaymentInProgress] = useState(false)
  const [addPaymentInProgress, setAddPaymentInProgress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [externalTipAmount, setExternalTipAmount] = useState(0)

  const submitted = useRef(false)
  const timeOutTimer = useRef(null)
  const timeOutTimer2 = useRef(null)
  const timeOutTimer3 = useRef(null)
  const bankCardPaymentAmount = useRef(0)
  const bankCardPaymentTippAmount = useRef(0)

  const getData = (message) => {}

  const { messageSecondary } = useMain(getData)

  useEffect(() => {
    return () => clearTimeout(timeOutTimer3.current)
  }, [])

  useEffect(() => {
    let timer
    if(printingReady) {
      if(submitOrderAfterPayment) {
        startOrder()
      } else {
        setFirstAvailableBasket()
      }
      timer = setTimeout(() => closeAllModal(), 300)
      localStorage['firstStartTutorialFinished'] = 1
      localStorage.removeItem('firstStartTutorialOn')
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line
  }, [printingReady])

  useEffect(() => {
    if(!externalOrderPayment) {
      if(submitOrderAfterPayment && (!submitted.current) && paymentsNeeded === 0 && orderState === 0) {
        submitted.current = true
        setLoading(true)
        timeOutTimer2.current = setTimeout(() => {
          toast.error("Submit order timeout")
          setLoading(false)
        }, 3000)
        submitOrder()
        .then(res => {
          if(!res.success) {
            console.log("Order submit failed", res)
            setLoading(false)
          }
        })
        .catch(e => {
          console.log("Order submit failed, caught: ", e)
          setLoading(false)
        })
        .finally(res => {
          clearTimeout(timeOutTimer2.current)
        })
      }
      if(!submitOrderAfterPayment && orderState !== 0 && paymentsNeeded === 0) {
        setLoading(true)
        handlePrinting()
      }
    }
    return () => clearTimeout(timeOutTimer2.current)
    // eslint-disable-next-line
  }, [paymentsNeeded, submitOrderAfterPayment, externalOrderPayment])

  useEffect(() => {
    if(submitOrderAfterPayment && orderNumber != null) handlePrinting()
    // eslint-disable-next-line
  }, [submitOrderAfterPayment, orderNumber])

  useEffect(() => {
    if(paymentsNeeded === 0 && orderNumber != null) {
      messageSecondary({ action: "updateStatus", data: "sell" })
      messageSecondary({ action: "tipChange", data: true })
    }
    // eslint-disable-next-line
  }, [orderNumber, paymentsNeeded])

  useEffect(() => {
    updatePayment(tipAmount)
    bankCardPaymentTippAmount.current = tipAmount
    // eslint-disable-next-line
  }, [tipAmount])

  const handleAddPayment = (params) => {
    setAddPaymentInProgress(true)
    console.log("Payment params", params.paymentAgentID, params.amount, params.transactionID, params.data, params.uuid, params.agentType)
    timeOutTimer3.current = setTimeout(() => {
      toast.error("Add payment timeout")
      setAddPaymentInProgress(false)
    }, 30000)
    if(externalOrderPayment) {
      submitPayment(params.agentType, params.paymentAgentID, params.amount, params.transactionID, params.data).then(res => {
        if(res.success) {
          handleExternalOrderPrint(res.data)
        } else {
          console.log("Payment error", res)
          toast.error("Payment error")
        }
        setAddPaymentInProgress(false)
        clearTimeout(timeOutTimer3.current)
      })
    } else {
      addPayment(params.paymentAgentID, params.amount, params.transactionID, params.data, params.stornoUUID, params.agentType)
      .then(res => {
        if(res.success) {
          console.log("Payment added", res)
        } else {
          console.log("Payment error", res)
          toast.error("Payment error")
        }
        setAddPaymentInProgress(false)
        clearTimeout(timeOutTimer3.current)
      })
    }
  }

  const handlePaymentTypeChange = (agent) => {
    setSelectedPayment(agent)
  }

  const handleSetCurrent = (amount) => {
    setCurrentAmount(amount)
  }

  const addCardPayment = (amount, paymentAgentID) => {
    if(+amount === 0) return null
    let params = {
      paymentAgentID, 
      amount, 
      transactionID: uuidv4(), 
      data: {}, 
      agentType: "bankCard"
    }
    handleAddPayment(params)
    setCurrentAmount("0")
  }

  const addOtherPayment = (amount, paymentAgentID, agentType) => {
    if(+amount === 0) return null
    let params = {
      paymentAgentID, 
      amount, 
      transactionID: uuidv4(), 
      data: {}, 
      agentType
    }
    handleAddPayment(params)
    setCurrentAmount("0")
  }

  const addCashPayment = (amount, paymentAgentID, agentType) => {
    console.log("addCashPayment", amount, paymentAgentID, agentType)
    if(+amount === 0) return null;
    let params = {
      paymentAgentID, 
      amount: +amount % 5 !== 0 ? Math.round(amount / 5) * 5 : amount, 
      transactionID: uuidv4(), 
      data: {}, 
      agentType
    }
    handleAddPayment(params)
    setCurrentAmount("0")
  }
  
  const proceedPayment = (amount, selectedPayment) => {

    const handleClickOnChangeModal = (roundedPaymentsNeeded, paymentAgentID, agentType) => {
      popModal()
      addCashPayment(roundedPaymentsNeeded, paymentAgentID, agentType)
    }
    console.log("Proceed payment, selected payment:", selectedPayment)
    if(selectedPayment.agentType === "cash") {
      let roundedAmount = Math.round(amount / 5) * 5
      let roundedPaymentsNeeded = Math.round(paymentsNeeded / 5) * 5
      console.log("round", roundedAmount, roundedPaymentsNeeded)
      if (roundedAmount > roundedPaymentsNeeded) {
        loadModal(
          <div className="open-in-modal-root">
            <div className="text">{getT("modal.ChangeModalDesign.text")}: {roundedAmount - roundedPaymentsNeeded} Ft</div>
            <div className="button-cotainer">
              <div className="btn" onClick={() => handleClickOnChangeModal(roundedPaymentsNeeded, selectedPayment.paymentAgentID, selectedPayment.agentType)}>
                {getT("modal.buttonResponse.ok")}
              </div>
            </div>
          </div>
        )
      } else {
        addCashPayment(roundedAmount, selectedPayment.paymentAgentID, selectedPayment.agentType)
      }
    }
    if(selectedPayment.agentType === "bankCard") {
      if(selectedPayment?.useTerminal?.terminalType == null) {
        addCardPayment(amount, selectedPayment.paymentAgentID)
      } else {
        startBankCardPayment(amount, selectedPayment)
      }
    }
    if(selectedPayment.agentType === "vip") {
      payVipMoney(amount, selectedPayment.paymentAgentID).then(res => {
        if(res.success) {
          console.log(res)
        } else {
          toast.error(getT("modal.vipError.payment"))
        }
      })
    }
    if(selectedPayment.agentType !== "bankCard" && selectedPayment.agentType !== "cash" && selectedPayment.agentType !== "vip") {
      addOtherPayment(amount, selectedPayment.paymentAgentID, selectedPayment.agentType)
    }
  }

  const startBankCardPayment = (amount, selectedPayment) => {
    if(amount === 0) {
      console.log("Start bank card payment failed, amount 0")
      toast.error(getT("modal.bankCardError.amountNull"))
      return null
    }
    if(selectedPayment?.useTerminal?.terminalType === "festipayPswTerminal" && (amount < paymentsNeeded || paymentsTotal > 0)) {
      console.log("Only full amount with PSW")
      toast.error(getT("modal.bankCardError.onlyFullPayment"))
      return null
    }
    setBankCardPaymentInProgress(true)
    timeOutTimer.current = setTimeout(() => {
      setBankCardPaymentInProgress(false)
      toast.error(getT("modal.bankCardError.timeout"))
      loadModal(
      <AddBankCardPaymentModal 
        popModal={popModal} 
        addCardPayment={addCardPayment}
        amount={amount}
        paymentAgentID={selectedPayment.paymentAgentID}
      />)
    }, 300000)

    let maxChars = deviceParams?.printer?.selected ? deviceParams?.printer?.maxChars || 32 : 
      (deviceParams?.posPrinter?.selected ? printers?.[deviceParams?.posPrinter?.uuid]?.maxChars || 32 : 32)

    startPayment(selectedOrderUUID, +amount, selectedPayment.useTerminal, tipAmount, (res) => onTransactionUpdated(+amount, selectedPayment, res), maxChars).then(res => {
      bankCardPaymentAmount.current = +amount - tipAmount
      bankCardPaymentTippAmount.current = tipAmount
      if(res.success) {
        console.log(JSON.stringify(res.data, null, 2))
        if(["festipayPswTerminal", "saltpayTerminal"].includes(selectedPayment?.useTerminal?.terminalType)) {
          clearTimeout(timeOutTimer.current)
        } else {
          let params = {
            paymentAgentID: selectedPayment.paymentAgentID,
            amount: res.data?.provider?.amount || 0,
            transactionID: res.data?.provider?.transactionId || 0,
            data: res.data,
            agentType: selectedPayment.agentType
          }
          handleAddPayment(params)
          if(+amount < paymentsNeeded) {
            startBankCardSlipPrint(res.data?.provider?.printSlip || null)
          } else {
            setCustomerBankcardSlip(res.data?.provider?.printSlip || null)
          }
          setCurrentAmount("0")
          clearTimeout(timeOutTimer.current)
          setBankCardPaymentInProgress(false)
        }
      } else {
        logError(1, 100, "", res.data)
        toast.error(getT("modal.bankCardError.error"))
        clearTimeout(timeOutTimer.current)
        setBankCardPaymentInProgress(false)
        loadModal(
        <AddBankCardPaymentModal 
          popModal={popModal} 
          addCardPayment={addCardPayment}
          amount={amount}
          paymentAgentID={selectedPayment.paymentAgentID}
        />)
      }
    })
  }

  const onTransactionUpdated = (amount, selectedPayment, res) => {
    if(res.success) {
      if(res.code === 201) {
        let params = {
          paymentAgentID: selectedPayment.paymentAgentID,
          amount: selectedPayment?.useTerminal?.terminalType === "festipayPswTerminal" ? +res.data.terminalResponse.tipAmount+amount : res.data.provider.amount,
          transactionID: res.data.provider.transactionId,
          data: res.data,
          agentType: selectedPayment.agentType
        }
        setCustomerBankcardSlip(res.data?.provider?.printSlip || null)
        handleAddPayment(params)
        setBankCardPaymentInProgress(false)
        setCurrentAmount("0")
      } else {
        if(res.data.posState != null && +res.data.posState !== 1) {
          messageSecondary({ action: "tipChange", data: false })
        }
      }
    } else {
      setBankCardPaymentInProgress(false)
      toast.error(getT("modal.bankCardError.transactionFailed"))
      if (res.code !== 666) {
        logError(1, 100, "", res.data)
      }
      loadModal(
        <AddBankCardPaymentModal 
          popModal={popModal} 
          addCardPayment={addCardPayment}
          amount={bankCardPaymentAmount.current + bankCardPaymentTippAmount.current}
          paymentAgentID={selectedPayment.paymentAgentID}
        />
      )
    }
  }

  return (
    <PaymentContext.Provider
      value={{
        selectedPayment,
        handlePaymentTypeChange,
        currentAmount,
        handleSetCurrent,
        paymentsNeeded: external ? ((selectedOrdersSum?.sumPaymentsNeeded || 0) + externalTipAmount) : paymentsNeeded,
        paymentsTotal,
        totalPrice,
        order,
        payments,
        invoices,
        proceedPayment,
        devicePaymentAgents,
        handleAddPayment,
        discountPrice,
        serviceFee,
        tipAmount,
        setTipAmount: external ? setExternalTipAmount : setTipAmount,
        bankCardPaymentInProgress,
        addPaymentInProgress,
        orderVipID,
        loading,
        externalPayment: external,
        externalTipAmount,
        orderObjects
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  )
}

export { PaymentContext, PaymentProvider }