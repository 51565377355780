import { useIPanelApi, useClient } from "okeoke.client"

export default function usePrepCountersApi() {

    const { post, get } = useIPanelApi()
    const { brandID } = useClient()

    const getPrepCounters = async (filter) => {
        try {
            const response = await get(`brand/catalog/prepCounters/all?brandID=${brandID}&filter=${JSON.stringify(filter || {})}`)
            return response
        } catch (error) {
            console.log(error)
            return { success: false }
        }
    }
    const getPrepCounter = async (counterUUID) => {
        try {
            const response = await get(`brand/catalog/prepCounters/${counterUUID}?brandID=${brandID}`)
            return response
        } catch (error) {
            console.log(error)
            return { success: false }
        }
    }
    const getPrepCounterItems = async (counterUUID) => {
        try {
            const response = await get(`brand/catalog/prepCounters/items/${counterUUID}?brandID=${brandID}`)
            return response
        } catch (error) {
            console.log(error)
            return { success: false }
        }
    }
    const setPrepCounter = async (prepCounter) => {
        let body = {
            brandID,
            prepCounter
            /*prepCounter: {
                uuid,
                name
            }*/
        }
        try {
        const response = await post(`brand/catalog/prepCounters`, body)
        return response
        } catch (error) {
        console.log(error)
        return { success: false }
        }
    }
    return {
        getPrepCounters,
        getPrepCounter,
        getPrepCounterItems,
        setPrepCounter
    }
}