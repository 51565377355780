import { useState, createContext, useEffect, useRef } from "react"
import { useOrder, useClient, useBaskets, useLocation, useExternalPayment } from "okeoke.client"
import cloneDeep from "lodash.clonedeep"

const SelectedOrderContext = createContext()

function SelectedOrderContextProvider(props) {

  const { ordersInited, client } = useClient()
  const [selectedOrderUUID, setSelectedOrderUUID] = useState(null)
  const [selectedExternalOrders, setSelectedExternalOrders] = useState({})
  const { incItemPayment, sums, itemPayments, decItemPayment, setItemPayment, submitPayment } = useExternalPayment(Object.values(selectedExternalOrders || {}).map(order => order.uuid))
  const [externalOrderPayment, setExternalOrderPayment] = useState(false)
  const [swiper, setSwiper] = useState(null)
  const { location } = useLocation()

  const { 
    addItem, 
    updateItem, 
    startNewOrder, 
    orderState, 
    totalPrice, 
    discountPrice, 
    sumPrice,
    paymentsNeeded, 
    serviceFee, 
    itemCount, 
    order, 
    setTipAmount, 
    tipAmount,
    takeAway,
    setTakeAway,
    abort,
    setDiscount, 
    setItemDiscount, 
    unsetDiscount, 
    paymentsTotal,
    payments,
    submit,
    addPayment,
    orderNumber,
    addStatus,
    invoices,
    orderIdentifier,
    setOrderIdentifier,
    notes,
    setNotes,
    discountAmount,
    sumServiceFee,
    packagingPrice,
    deliveryCost,
    vipID,
    setVipID,
    updateItemCount,
    setTable,
    tableInfo,
    payVipMoney,
    refundVipMoney,
    orderItemsAssembled
  } = useOrder(null, selectedOrderUUID)
  
  const { baskets } = useBaskets()
  const orderInited = useRef(false)
  const updateQueue = useRef([])
  const timer = useRef(null)

  useEffect(() => {
    return () => clearTimeout(timer.current)
  }, [])

  useEffect(() => {
    setExternalOrderPayment(Object.keys(selectedExternalOrders || {}).length !== 0)
  }, [selectedExternalOrders])

  useEffect(() => {
    if(ordersInited) {
      let savedOrderUUID = localStorage["pos-selected-order-uuid"]
      if(savedOrderUUID == null) {
        if(Object.values(baskets || {}).length === 0 && !orderInited.current) {
          orderInited.current = true
          startOrder()
        }
      } else {
        if(client?.orders?.orders?.[savedOrderUUID]?.order?.orderState == null && !orderInited.current) {
          orderInited.current = true
          startOrder()
        } else {
          setSelectedOrderUUID(savedOrderUUID)
        }
      }
    }
    // eslint-disable-next-line
  }, [ordersInited, baskets])

  const setItemCount = (orderItemUUID, count) => {
    if(orderItemsAssembled[orderItemUUID] == null) return null
    let submitItem = cloneDeep(orderItemsAssembled[orderItemUUID])
    submitItem.count = count
    updateItem(submitItem)
  }

  const handleSetSwiper = (e) => {
    if(e != null) setSwiper(e.swiper)
  }

  const setFirstAvailableBasket = () => {
    const basketArray = Object.keys(baskets || {})
    if(basketArray.length === 0) {
      startOrder()
    } else {
      handleSelectOrderUUID(basketArray[0])
    }
  }

  const abortOrder = () => {
    abort()
    startOrder()
  }

  const addToUpdateQueue = (updateData) => {
    clearTimeout(timer.current)
    updateQueue.current.push(updateData)
    timer.current = setTimeout(() => {
      runQueue()
    }, 300)
  }

  const runQueue = () => {
    let queue = [...updateQueue.current]
    console.log(queue)
    updateQueue.current = []
    console.log("start")
    for(let item of queue) {
      updateItemCount(item.orderItemUUID, item.count)
    }
    console.log("stop")
  }

  const clearUpdateQueueTimer = () => {
    clearTimeout(timer.current)
  }

  const startUpdate = () => {
    timer.current = setTimeout(() => {
      runQueue()
    }, 300)
  }

  const startOrder = () => {
    if(swiper != null && typeof swiper.slideTo === "function") swiper.slideTo(0)
    let res = startNewOrder({ paymentType: "Offline" })
    handleSelectOrderUUID(res.order.uuid)
  }

  const handleSelectOrderUUID = (uuid) => {
    setSelectedOrderUUID(uuid)
    localStorage["pos-selected-order-uuid"] = uuid
  }

  const submitOrder = () => {
    return submit()
  }

  const submitAndStartNewOrder = () => {
    submit().then(res => {
      if(res.success) {
        startOrder()
      } else {
        console.log("Submit order error", res)
      }
    })
  } 

  const selectOrderWithoutSave = (uuid) => {
    setSelectedOrderUUID(uuid)
  }

  const handleTipAmountSet = (amount) => {
    if(location?.localConfig?.tippType !== 0) setTipAmount(amount)
  }

  const addToExternalOrders = (orders) => {
    let newValues = { ...selectedExternalOrders }
    for(let order of orders) {
      if(newValues?.[order.uuid] == null) {
        newValues = {...newValues, [order.uuid]: order}
      } else {
        newValues[order.uuid] = order
      }
    }
    setSelectedExternalOrders(newValues)
  }

  const removeExternalOrder = (orderUUID) => {
    let newValues = { ...selectedExternalOrders }
    delete newValues?.[orderUUID]
    setSelectedExternalOrders(newValues)
  }

  const clearExternalOrders = () => {
    setSelectedExternalOrders({})
  }

  return (
    <SelectedOrderContext.Provider
      value={{
        selectedOrderUUID, 
        handleSelectOrderUUID, 
        selectOrderWithoutSave, 
        addItem, 
        updateItem, 
        orderState, 
        totalPrice, 
        discountPrice, 
        paymentsNeeded: externalOrderPayment ? (sums?.sumPaymentsNeeded || 0) : paymentsNeeded, 
        serviceFee, 
        itemCount, 
        order, 
        setTipAmount: handleTipAmountSet, 
        tipAmount, 
        takeAway, 
        setTakeAway,
        orderItemsAssembled, 
        abortOrder, 
        setDiscount, 
        setItemDiscount, 
        unsetDiscount, 
        startOrder, 
        paymentsTotal, 
        payments, 
        addPayment, 
        orderNumber, 
        addStatus,
        invoices, 
        submitOrder, 
        orderIdentifier,
        setOrderIdentifier,
        setFirstAvailableBasket, 
        notes,
        setNotes, 
        setSwiper: handleSetSwiper,
        swiper,
        sumPrice,
        discountAmount,
        sumServiceFee,
        packagingPrice,
        deliveryCost,
        orderVipID: vipID,
        setOrderVipID: setVipID,
        addToUpdateQueue,
        clearUpdateQueueTimer,
        startUpdate,
        setTable,
        tableInfo,
        submitAndStartNewOrder,
        payVipMoney,
        refundVipMoney,
        selectedExternalOrders,
        addToExternalOrders,
        removeExternalOrder,
        clearExternalOrders,
        externalOrderPayment,
        setItemCount,
        selectedOrdersSum: sums,
        incItemPayment,
        itemPayments,
        decItemPayment,
        setItemPayment,
        submitPayment
      }}
    >
      {props.children}
    </SelectedOrderContext.Provider>
  )
}
export { SelectedOrderContext, SelectedOrderContextProvider }