import { useContext } from "react"
import { OrderStatus, OrderStates, useOrder, useClient } from "okeoke.client"
import useMultilang from "intl/useMultilang"
import Modal from "components/Modal/Modal"
import Payment from "views/Payment"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { MainContainerContext } from "contexts/MainContainerProvider"

function OrderController(props) {

  const { orderUUID, onClose, setLoading } = props
  const { getT } = useMultilang()
  const { addModal } = useContext(ModalContext)
  const { selectOrderWithoutSave, addToExternalOrders, clearExternalOrders } = useContext(SelectedOrderContext)
  const { abort, addStatus, paymentsNeeded, orderState, paymentState, orderNumber, orderIdentifier } = useOrder(null, orderUUID)
  const { socketAuthenticated } = useClient()
  const { orderObjects } = useContext(MainContainerContext)

  const handlePay = () => {
    selectOrderWithoutSave(orderUUID)
    addModal(
      <Modal key={uuidv4()} className="payment-modal">
        <Payment />
      </Modal>
    )
  }

  const handleExternalPay = () => {
    selectOrderWithoutSave(orderUUID)
    addToExternalOrders([{ 
      uuid: orderUUID, 
      orderNumber: orderNumber, 
      orderIdentifier: orderIdentifier,
      paymentsNeeded: paymentsNeeded
    }])
    addModal(
      <Modal key={uuidv4()} className="payment-modal" closeCallback={clearExternalOrders}>
        <Payment external={true} orderObjects={orderObjects} />
      </Modal>
    )
  }

  const addOrderTimeEstimate = (minutes) => {
    if(!socketAuthenticated) return null
    setLoading(true)
    addStatus(OrderStatus.ORDER_TIME_ESTIMATE, { minutes }).finally(() => setLoading(false))
    onClose()
  }

  const setOrderSeen = () => {
    if(!socketAuthenticated) return null
    setLoading(true)
    addStatus(OrderStatus.ORDER_RECEIVED, {}).finally(() => setLoading(false))
    onClose()
  }

  const setOrderReady = () => {
    if(!socketAuthenticated) return null
    setLoading(true)
    addStatus(OrderStatus.ORDER_DONE, {}).finally(() => setLoading(false))
    onClose()
  }

  const setOrderCloseSuccess = () => {
    if(!socketAuthenticated) return null
    setLoading(true)
    addStatus(OrderStatus.ORDER_CLOSED_SUCCESS, {}).finally(() => setLoading(false))
    onClose()
  }

  const abortOrder = () => {
    if(!socketAuthenticated) return null
    abort()
  }

  return (
    <>
      <div className="card-order-controller-background animation-appear-seventy" onClick={onClose}/>
      <div className="card-order-controller animation-appear-full">
        {orderState === OrderStates.INSERTED && <div className="btn btn-ready" onClick={setOrderSeen}>{getT("orderController.orderSeen")}</div>}
        {orderState === OrderStates.SEEN && <>
          <div className="btn btn-estimate" onClick={() => addOrderTimeEstimate(5)}>
            <div className="number">5</div> 
            <div className="text">{getT("orderedItem.ticket.minute")}</div>
          </div>
          <div className="btn btn-estimate" onClick={() => addOrderTimeEstimate(10)}>
            <div className="number">10</div> 
            <div className="text">{getT("orderedItem.ticket.minute")}</div> 
          </div>
          <div className="btn btn-estimate" onClick={() => addOrderTimeEstimate(20)}>
            <div className="number">20</div> 
            <div className="text">{getT("orderedItem.ticket.minute")}</div> 
          </div>
          <div className="btn btn-estimate" onClick={() => addOrderTimeEstimate(30)}>
            <div className="number">30</div> 
            <div className="text">{getT("orderedItem.ticket.minute")}</div> 
          </div>
        </>}
        {orderState === OrderStates.PREPARING && <div className="btn btn-ready" onClick={setOrderReady}>{getT("orderController.orderReady")}</div>}
        {orderState === OrderStates.DONE && paymentsNeeded === 0 && <div className="btn btn-done" onClick={setOrderCloseSuccess}>{getT("orderedItem.ticket.done")}</div>}
        <div className="bottom-container">
          {paymentsNeeded !== 0 && (paymentState?.paymentTypeAllowed === 0 || paymentState?.paymentTypeAllowed === 1) && <div className="btn btn-pay" onClick={handlePay}>{getT("orderedItem.ticket.paying")}</div>}
          {paymentsNeeded !== 0 && (paymentState?.paymentTypeAllowed === 0 || paymentState?.paymentTypeAllowed === 2) && <div className="btn btn-pay btn-external-pay" onClick={handleExternalPay}>{getT("orderedItem.ticket.externalPaying")}</div>}
          {paymentsNeeded !== 0 && <div className="btn btn-abort" onClick={abortOrder}>{getT("orderedItem.ticket.abort")}</div>}
          <div className="btn btn-cancel" onClick={onClose}>{getT("orderedItem.ticket.cancel")}</div>
        </div>
      </div>
    </>
  )
}
export default OrderController