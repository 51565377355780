import { OrderStates } from "okeoke.client"
import useMultilang from "intl/useMultilang"
import useCatalogContext from "hooks/useCatalogContext"

function OrderCardItem(props) {

  const { item, orderState, onClick } = props

  const { getT, getName } = useMultilang()

  const { modifiers } = useCatalogContext()

  if(item == null) return null

  return (
    <div className={`order-card-item-root ${orderState >= OrderStates.ORDER_DONE ? "ready" : ""}`} onClick={onClick}>
      <div className="row">
        <div className="item-count">{item.count}</div>
        <div className="item-name">
          {getName(item.name)} 
          {item.takeAway && ` (${getT("openOrders.orderCard.item.takeAway")})`}
        </div>
      </div>
      <div className="item-size">{getName(item.sizeName)}</div>
      {item.modifiers.length > 0 && <div className="item-modifiers">
        {item.modifiers
        .filter(modifier => modifier.count > 0)
        .map((modifier, index) =>
          <div key={index} className="modifier-container">
            <div className="modifier-count">{modifier.count}</div>
            <div className="modifier-name">{getName(modifiers?.[modifier.modifierUUID]?.name)}</div>
          </div>
        )}
      </div>}
      {item.notes !== "" && <div className="item-notes">{item.notes}</div>}
    </div>
  )
}
export default OrderCardItem