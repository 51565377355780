import { useContext } from "react"
import VipModal from "./VipModal"
import DiscountModal from "./DiscountModal"
import NotesModal from "./NotesModal"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import LoadSvg from "components/LoadSvg-v2"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"
import TableModal from "./TableModal"

function OrderActions(props) {

    const { toggleShowItemDetails, showDetails } = props

    const { takeAway, setTakeAway, setNotes, notes, setTable, tableInfo } = useContext(SelectedOrderContext)
    const { loadModal, popModal } = useContext(ModalContext)
    const { featureList } = useContext(MainContext)

    const openVipModal = () => loadModal(<VipModal popModal={popModal} />, popModal)

    const openDicountModal = () => loadModal(<DiscountModal popModal={popModal} />, popModal)

    const openNotesModal = () => loadModal(<NotesModal notes={notes} setNotes={setNotes} popModal={popModal} />, popModal)

    const openTableModal = () => loadModal(<TableModal tableInfo={tableInfo} setTable={setTable} popModal={popModal} />, popModal)

    return(
        <div className="order-summary-header-btn-container">
            {featureList.tableManager === 1 && <div className={`action-button table-btn`} onClick={openTableModal}><LoadSvg name="table"/></div>}
            <div className={`action-button ${showDetails ? "selected" : ""}`} onClick={toggleShowItemDetails}><div className="inner-bg"></div><LoadSvg name="details"/></div>
            <div className={`action-button`}><LoadSvg name="notes" onClick={openNotesModal}/></div>
            {featureList.vip === 1 && <div className={`action-button vip-btn`} onClick={openVipModal}><LoadSvg name="payCard"/></div>}
            {featureList.localDiscounts === 1 && <LoadSvg onClick={openDicountModal} name="percent" className={"action-button percent-btn"}/>} 
            <div className={`action-button ${takeAway ? "selected" : ""}`} onClick={()=>{setTakeAway(true)}}><div className="inner-bg"></div><LoadSvg name="TakeAwayIcon"/></div>
            <div className={`action-button ${!takeAway ? "selected" : ""}`} onClick={()=>{setTakeAway(false)}}><div className="inner-bg"></div><LoadSvg name="EatInIcon"/></div>
        </div>
    )
}
export default OrderActions