import { useContext, useState, useEffect } from "react"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"
import { useVip } from "okeoke.client"
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import Numpad from "components/Numpad"
import { InputHandlerContext } from "contexts/InputHandlerProvider"
import NotAvailable from "components/NotAvailable"

function VipModal() {
  const { popModal } = useContext(ModalContext)
  const { featureList } = useContext(MainContext)
  const { orderVipID, setOrderVipID } = useContext(SelectedOrderContext)
  const { setMachineProvidedFunction } = useContext(InputHandlerContext)

  const [vipCode, setVipCode] = useState(orderVipID)
  const { vipID, firstName, points, money } = useVip(vipCode)
  const { getT } = useMultilang()

  useEffect(() => {
    if(vipID != null && vipID !== "") setOrderVipID(vipID)
    // eslint-disable-next-line
  }, [vipID])

  useEffect(() => {
    setMachineProvidedFunction((e) => handleVipID(e))
    return () => setMachineProvidedFunction(null)
    // eslint-disable-next-line
  }, [])

  const handleChangeVipCode = (code) => {
    setVipCode(code)
  }

  const handleVipID = (input) => {
    let id = replaceAll(input, {"ö": 0})
    setVipCode(id)
  }

  const replaceAll = (str, mapObj) => {
    if(str == null) return null
    let re = new RegExp(Object.keys(mapObj).join("|"),"gi")

    return str.replace(re, function(matched){
        return mapObj[matched.toLowerCase()]
    })
  }

  const removeUser = () => {
    setOrderVipID("")
    setVipCode("")
  }

  if(!featureList.vip) return <NotAvailable />

  return (
    <div className="vip-modal-root">
      <div className="header">
        <div className="back-icon" onClick={popModal}>
            <LoadSvg name={"ArrowLeftIcon"} />
          </div>
        <div className="header-text" onClick={popModal}>{getT("orderItem.view.back")}</div>
        <div className="title">{getT("vip.header")}</div>
      </div>
      <div className="body">
        <div className="numpad-container">
          <Numpad onChange={handleChangeVipCode} type={"basic"} limit={16} />
        </div>
        <div className="vip-info-container">
          <div className="title">{getT("vip.customerData")}</div>
          <div className="info-rows">
            <div className="info-row">
              <div className="label">{getT("vip.name")}</div>
              <div className="value">{orderVipID != null ? (firstName || getT("vip.noVipUserSet")) : getT("vip.noVipUserSet")}</div>
            </div>
            <div className="info-row">
              <div className="label">{getT("vip.points")}</div>
              <div className="value">{orderVipID != null ? points : 0}</div>
            </div>
            <div className="info-row">
              <div className="label">{getT("vip.money")}</div>
              <div className="value">{orderVipID != null ? money : 0}</div>
            </div>
            {/* <div className="info-row">
              <div className="label">{getT("vip.id")}</div>
              <div className="value">{orderVipID || getT("vip.noVipIDSet")}</div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="btn btn-warning" onClick={removeUser}>
          <LoadSvg name="BinIcon"/>
          {getT("vip.removeUserFromOrder")}
        </div>
        <div className="btn btn-save" onClick={popModal}>{getT("vip.done")}</div>
      </div>
    </div>
  )
}
export default VipModal

//41617