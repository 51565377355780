import LoadSvg from "components/LoadSvg-v2"

function LoadingScreen() {
    return(
        <div className="loading-screen hidden appear">
            <div className="logo-container">
                <LoadSvg name="barSoftLogo" />
            </div>
            <div className="img-container floating-animation">
                <img src="/assets/businessman.png" alt="businessman" />
            </div>
            <div className="text">Betöltjük az inspirációt, készülj a csodákra!</div>
        </div>
    )
}
export default LoadingScreen